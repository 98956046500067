import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';

import $ from "jquery";

import '../../assets/css/ajax-loader.gif';
import '../../assets/css/owl.video.play.png';
import '../../assets/css/customize-proxtal.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import 'animate.css';
import MapImage from "../../assets/images/world-map.png";
import bannerImage from "../../assets/images/banner-img-right.png";
import aboutImage from "../../assets/images/why-proxtal-img.png";
import aboutImage1 from "../../assets/images/why-proxtal-img.jpg";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomLoader from 'components/CustomLoader';

const Index = () => {
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [allDesignation, setAllDesignation] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [designation, setDesignation] = useState('');
  const [experience, setExperience] = useState('');
  const [location, setLocation] = useState('');
  const [allLocations, setAllLocations] = useState([]);
  const { token } = useSelector(selectAuth);
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  const fetchProjectJobs = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}api/get-project-jobs`, {
        method: 'GET', // Adjust the HTTP method as needed
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      setData(result?.projects);
      setJobs(result?.jobs);
      setAllDesignation(result?.designations);
      setAllLocations(result?.locations);
      setSectors(result?.sectors);
      //console.log('allDesignation', allDesignation)
      setTimeout(() => {
        setLoading(false);
      }, 2000); // 1 second delay

    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 1 second delay
      //console.error('Error fetching job data:', error);
    }
  };

  const handleSectorChange = (event) => {
    const sectorName = event.target.value;
    setSelectedSector(sectorName);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setDesignation(value);

    if (value.length > 0) {
      const filtered = allDesignation.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDesignations(filtered);
    } else {
      setFilteredDesignations([]);
    }
  };

  const handleSelectDesignation = (selected) => {
    setDesignation(selected);
    setFilteredDesignations([]); // Hide suggestions after selection
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setLocation(value);

    if (value.length > 0) {
      const filtered = allLocations.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLocations(filtered);
    } else {
      setFilteredLocations([]);
    }
  };
  const handleSelectLocation = (selected) => {
    setLocation(selected);
    setFilteredLocations([]); // Hide suggestions after selection
  };

  const getSearchResult = async () => {
    try {
      const searchData = {
        designation,
        selectedSector,
        // experience,
        location,
      };
      const queryParams = new URLSearchParams(searchData).toString();
      // window.location.href = searchQuery;
      navigate(`/all-jobs?${queryParams}`, { replace: true });
      // Handle the response data as needed
    } catch (error) {
      //console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    fetchProjectJobs();
  }, []);
  return (
    <BasicLayout>
      {loading && (
        <div className="spinner-overlay">
          <CustomLoader />
        </div>
      )}
      {/* Steps */}
      <div className='banner-wrapper animate__animated animate__fadeInUp'>
        <div className="container">
          <div className="row d-blck d-md-flex align-items-center justify-content-center">
            <div className="col-md-9 home-search">
              <h2 className='text-center mb-3 mt-5'>Find your <span>dream job</span> now</h2>
              <div className="d-block d-md-flex rounded search-wrapper-inner shadow p-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter designation"
                    aria-label="Skills"
                    value={designation}
                    onChange={handleInputChange}
                  />
                  {filteredDesignations.length > 0 && (
                    <ul className="list-group select-position position-absolute list-unstyled overflow-auto" style={{ maxHeight: '300px' }} >
                      {filteredDesignations.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item list-group-item-action"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSelectDesignation(item)}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className="input-group-prepend">
                    <button
                      className="btn select-sector dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedSector || 'Select sector'}
                    </button>
                    <div className="dropdown-menu select-sector">
                      {sectors.map((sectorItem) => (
                        <a
                          className="dropdown-item"
                          key={sectorItem.name}
                          onClick={() => setSelectedSector(sectorItem.name)}
                          style={{ cursor: 'pointer' }}
                        >
                          {sectorItem.name}
                        </a>
                      ))}
                    </div>
                  </div>

                  {/* <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle border-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {experience || 'Select experience'}
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('0-2')}>0-2 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('2-5')}>2-5 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('5-10')}>5-10 Years</a>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setExperience('10+')}>10+ Years</a>
                    </div>
                  </div> */}

                  <div className="input-group-prepend">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Location"
                      aria-label="Location"
                      value={location}
                      onChange={handleLocationChange}
                    />
                    {filteredLocations.length > 0 && (
                      <ul className="list-group select-location position-absolute list-unstyled overflow-auto" style={{ maxHeight: '400px' }}>
                        {filteredLocations.map((item, index) => (
                          <li
                            key={index}
                            className="list-group-item list-group-item-action"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSelectLocation(item)}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}

                  </div>

                  <div className="input-group-append">
                    <button
                      className="btn btn-warning search-btn"
                      type="button"
                      onClick={getSearchResult}
                    >
                      <span className="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 banner-content text-center">
              <h2>Unlock the Power of Collaboration</h2>
              <h3>A User-Friendly Platform<br></br> Matching International Development Professionals with #Projects</h3>
              
            </div>
            {/* <div className="col-md-6">
              <img src={bannerImage} alt="" />
            </div> */}
          </div>
        </div>

        {/* <div className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div> */}

      </div> {/* banner area end */}

{/* Call to Action */}
<div className='container'>
  <div className='call-to-action-1'>
  <div className="row d-flex align-items-center justify-content-center z-3">
    <div className="col-md-6 text-start z-3">
      <h2>Join as a Professional</h2>
      <p>Showcase your skills, explore tailored opportunities, and advance your career in international development. Register now to connect with impactful projects worldwide!</p>
    </div>
    <div className="col-md-4 text-center">
      {!token && (
        <Link to={'/sign-up-as-professional'} className="btn btn-warning mt-2 text-white">Register as a professional <i className="fa-solid fa-arrow-right-long"></i></Link>
      )}
    </div>
  </div>
  </div>
  
</div>

{/* Featured Job home page */}
<div className='featured-job-wrapper'>
  <div className="title-wrapper text-left">
    <h2>Featured Jobs</h2>
  </div>
  <div className='container'>
  <div className="row d-flex justify-content-center">
  {jobs?.map((job, index) => (
    <div key={index} className="col-md-4 col-6">
          <div className="featured-project" key={job?.id}>
          <div className="company-info">
            <div className="logo"><div className="alphabates">{job?.company?.company_name?.charAt(0)}</div></div>
            <div className="info">
              <h2 className='text-wrap'>{job?.title}</h2>
              {/* <p className="people-required">10 no. of Civil Engineer</p> */}
            </div>
          </div>
          {job?.description && (
            <>
              <div className="short-desc">
                <p><span dangerouslySetInnerHTML={{ __html: job.description.substring(0, 100) + "..." }} /></p>
              </div>
            </>
          )}
          <Link to={`/job/${job?.slug}`} className="btn btn-outline mt-3">
            View Details
          </Link>
        </div>
    </div>
                    
                  ))}
  </div>
  </div>
</div>
<div className="why-proxtal">
  <div className='container'>
    <div className='row d-flex align-items-center'>
      <div className='col-md-6'>
        <div className='title-wrapper'>
          <h3>Discover</h3>
          <h2>Why <span>Proxtal</span> Stands Out</h2>
        </div>
          <p>Proxtal is transforming the way international development professionals and organizations collaborate. With its intuitive platform, Proxtal connects professionals to projects that align with their skills and passions, creating seamless opportunities for career advancement. At the same time, organizations enjoy a simplified hiring process and access to a diverse pool of skilled professionals, ensuring the perfect match for their project needs.</p>
          
          <Link to={'/sign-up-as-professional'} className="btn btn-warning mt-3 text-white">Know More <i className="fa-solid fa-arrow-right-long"></i></Link>
      </div>
      <div className='col-md-6'>
        <div className='image-wrapper'>
          <img src={aboutImage1} />
        </div>
           
      </div>
    </div>
  </div>
</div>

<div className="job-category-home container mt-5 mb-5">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4></h4>
            <h2>Top Categories</h2>
            {/* <h3>and Get your Dream Job</h3> */}
          </div>
        </div>
        <div className="category-list">
          <div className="row d-flex align-items-stretch">
            {sectors?.map((sector, index) => (
              <div className="col-md-2 col-6 g-3" key={sector?.id}>
                <div className="card shadow h-100">
                  <div className="card-body align-items-center d-flex p-3">
                    <div className="catlist">
                      <h2>{sector?.name}</h2>
                      <p>{sector?.projects_count} Project Posted</p>
                    </div>
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>

      </div> {/* category */}

<div className='container why-proxtal-2'>
  <div className='row'>
    <div className='col-md-6 box-1'>
      <div className='title-wrapper'>
        <h2>For Organizations</h2>
      </div>
    
    <p>Proxtal offers a streamlined hiring process, allowing organizations to find the perfect candidate for their project needs. With our platform, you can:</p>
    
    <div className='row'>
      <div className='col-md-6'>
        <div className='info-box-1'>
          <i className="fa-solid fa-square-check"></i>
          <p>Skill-Based Matching: Find projects that align perfectly with your skills and interests.</p>
        </div>
          </div>
      <div className='col-md-6'>
        <div className='info-box-2'>
        <i className="fa-solid fa-square-check"></i>
        <p>Global Opportunities: Access a wide range Of projects from around the world.</p>
        </div>
        
      </div>
      <div className='col-md-6'>
        <div className='info-box-1'>
        <i className="fa-solid fa-square-check"></i>
        <p>User-Friendly Interface: Navigate and apply for projects with ease.</p>
        </div>
    
      </div>
      <div className=' col-md-6'>
        <div className='info-box-2'>
        <i className="fa-solid fa-square-check"></i>
        <p>Real-Time Updates: Stay informed with the latest project opportunities and updates.</p>
        </div>
      </div>

      <div className='col-md-12 text-center'>
        <Link to={'/sign-up-as-organization'} className="btn btn-warning mt-3 text-white">Register Now <i className="fa-solid fa-arrow-right-long"></i></Link>
      </div>

    </div>
    </div>
    <div className='col-md-6 box-2'>
      <div className='title-wrapper'>
        <h2>For Professionals</h2>
      </div>
    
    <p>Proxtal offers a streamlined hiring process, allowing organizations to find the perfect candidate for their project needs. With our platform, you can:</p>
    <div className='row'>
      <div className='col-md-6'>
        <div className='info-box-1'>
          <i className="fa-solid fa-square-check"></i>
          <p>Exclusive Job Opportunities - Unlock tailored roles matching your expertise and interests.</p>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='info-box-2'>
          <i className="fa-solid fa-square-check"></i>
          <p>Professional Networking - Connect with top employers and industry leaders effortlessly.</p>
        </div>
        
      </div>
      <div className='col-md-6'>
        <div className='info-box-1'>
          <i className="fa-solid fa-square-check"></i>
          <p>Skill Enhancement Resources - Access workshops, webinars, and tools to boost your career growth.</p>
        </div>
        
      </div>
      <div className='col-md-6'>
        <div className='info-box-2'>
        <i className="fa-solid fa-square-check"></i>
        <p>Career Support Services - Receive expert guidance on resumes, interviews, and career strategies.</p>
        </div>
        
      </div>
      <div className='col-md-12 text-center'>
        <Link to={'/sign-up-as-professional'} className="btn btn-warning mt-3 text-white"> Register Now <i className="fa-solid fa-arrow-right-long"></i></Link>
      </div>
    </div>
    </div>
  </div>
</div>


      {/* latest project */}
      <div className="container featured-projects mt-5">
        <div className="row d-flex align-items-center mt-4">
          <div className="col-xl-6">
            <div className="title-wrapper text-left">
              <h2>Featured Projects</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {data?.map((project, index) => (
            <div className="col-md-4 gx-5 mb-3" key={project?.id}>
              <div className="featured-project">
                <div className="company-info">
                  <div className="logo"><div className="alphabates">{project?.company?.company_name?.charAt(0)}</div></div>
                  <div className="info">
                    <h2 className='text-wrap'>{project?.name}</h2>
                    {/* <p className="people-required">10 no. of Civil Engineer</p> */}
                  </div>
                </div>
                {project?.features && (
                  <>
                    <div className="short-desc">
                      <p><span dangerouslySetInnerHTML={{ __html: project.features.substring(0, 80) + "..." }} /></p>
                    </div>
                  </>
                )}
                <Link to={`/project/${project?.slug}`} className="btn btn-outline mt-3">
                  View Details
                </Link>
              </div>
            </div>
          ))}
        </div>

      </div>

      {/* why proxtal deffrent */}

<div className='container'>
  <div className='why-choose-us-wrapper'>
    <div className='title-wrapper text-center'>
      <h2>What Sets <span>Proxtal</span> Apart</h2>
      <h3>The Game-Changer You Need</h3>
    </div>
    <div className='row d-flex align-items-stretch align-items-start justify-content-center g-2 gap-3'>
      <div className='col-md-3 benifits'>
        <h2>Key Advantages</h2>
        <hr></hr>
        <ul>
          <li><span>Explore</span> A Wide Pool of Skilled Candidates</li>
          <li><span>Save Time</span> with Efficient Solutions That Deliver Results</li>
          <li><span>Save Costs</span> with Smart and Efficient Solutions</li>
          <li><span>Optimize Workforce</span> and Reduce Staffing Overheads</li>
          <li><span>Less Effort</span> More Output, Greater Results</li>
          <li><span>Infracon Opportunities</span> Empowering Indian Firms in Infrastructure Development</li>
          <li><span>Save Time</span> Streamline Processes for Your Proposal Team</li>
        </ul>
      </div>
      <div className='col-md-4 benifits'>
        <h2>Proxtal</h2>
        <hr></hr>
        <ul>
          <li>Effortlessly Search and Find Top Candidates from a Pool of Pre-Screened Skill Experts—No Need for Multiple Job Portals.</li>
          <li>Quickly Find the Perfect Expert in No Time</li>
          <li>100% Free with No Hidden Costs.</li>
          <li>Build a Complete Project Team with Minimal Employees – Achieve More with Just One Expert</li>
          <li>Post Targeted Projects and Connect Directly with Matched Candidates Based on Location and Budget</li>
          <li>View Candidates' Infracon ID, Availability for Bidding, and Immediate Joining with Budget Details (For Indian Consultancy Firms)</li>
          <li>Build Your Expert Team in Just One Day for Bidding Projects</li>
        </ul>
      </div>
      <div className='col-md-4 benifits-o'>
        <h2>Others</h2>
        <hr></hr>
        <ul>
          <li>Multiple Platforms Required: You need to search and source candidates across various job portals, social media, referrals, and internal databases.</li>
          <li>Time-Consuming: The process takes too much time, leading to delays in project execution.</li>
          <li>High Costs: It is often too expensive to use multiple platforms and services for candidate sourcing.</li>
          <li>Large Team Needed: A dedicated team of many employees is required to manage the process effectively.</li>
          <li>Availability Issues: You must contact candidates individually to confirm their availability for specific job locations, budgets, and more—many experts deny availability for specific locations.</li>
          <li>Manual Candidate Outreach: You need to reach out to every candidate on other portals, which can be tedious and inefficient.</li>
          <li>Difficulty in Quick Bidding: Bidding in a short period of time becomes difficult due to a lack of key experts available on demand.</li>
        </ul>
        
      </div>
    </div>
  </div>
</div>
  <div className="container easy-steps mt-5 py-5">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4>Are you Looking for a Job ?</h4>
            <h2>Follow Easy Steps</h2>
            <h3>and Get your Dream Job</h3>
          </div>

        </div>
        <div className="row d-flex justify-align-center align-contents-center">
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> assignment </span></div>
              <h2>Get Register</h2>
              <p>Join now and unlock endless career opportunities.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> upload_file </span></div>
              <h2>Update CV</h2>
              <p>Showcase your skills—upload your CV to attract top employers.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> manage_search </span></div>
              <h2>Serch Jobs</h2>
              <p>Discover your ideal job from thousands of listings.</p>
            </div>
          </div>
          <div className="col-lg-3 gx-5">
            <div className="step-box">
              <div className="icon"><span className="material-symbols-outlined"> stacked_email </span></div>
              <h2>Apply For the Jobs</h2>
              <p>Take the next step—apply now and advance your career.</p>
            </div>
          </div>
        </div>

      </div>

      {/* Call to action area */}
      <div className="call-to-action-1">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-5 image-area text-end pe-5">
              <img src={MapImage} alt="" />
            </div>
            <div className="col-md-7 content-area">
              <h2>Unlock Your Dream Career Today!</h2>
              <p>Join thousands of professionals who have found their path to success with us. Register now and take the next step in your career journey!</p>
              {!token && (
                <Link to="/sign-up-as-professional" className="btn btn-warning mt-3">
                  Register Now
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* recent jobs */}


      {/* partners */}
      {/* <div className="partners container">
        <div className="row">
          <div className="title-wrapper text-center">
            <h4>Are you Looking for a Job ?</h4>
            <h2>Follow Easy Steps</h2>
            <h3>and Get your Dream Job</h3>
          </div>

        </div>

        <div className="owl-carousel" id="partner-carousel">
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
          <div> Your Content </div>
        </div>
      </div> */}
    </BasicLayout>
  )
}

export default Index