import React from 'react'
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

const News = ({ news }) => {
    const convertDate = (date) => {
        const data = date;
        const formattedDeadline = data ? format(parseISO(data), 'eeee, MMMM d, yyyy') : '';
        return formattedDeadline;
    }
    return (
        <div className='col-md-12'>
            <div className="bg-white p-4 rounded shadow-md border border-2">
              <h2 className="emphasized">Top News</h2>
              <div className='mt-3'>
                {news.map((news, index) => (
                  <div key={news?.id} className="releated-news flex gap-3 mb-3">
                    <Link to={`/news-feed/${news?.slug}`} className="text-gray-700 hover:text-blue-600 transition-colors duration-200 text-sm">
                    <h6 className='text-primary'>{news?.title}</h6>
                    </Link>
                    <p className='text-muted'>By {news?.user?.name} || {convertDate(news?.created_at)}</p>

                  </div>
                ))}
              </div>
            </div>
          </div>
    )
}

export default News