import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import ShowApplyModal from './ShowApplyModal';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';

import ShowShareModal from './ShowShareModal';
const View = () => {
    const [data, setData] = useState([]);
    const [recomProject, setRecomProject] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, selecetdCompanyName] = useState('');
    const [projectId, selecetdProjectId] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [positions, setPositions] = useState([]);
    const [requiredPositions, setRequiredPositions] = useState({});
    const [isReadMore, setIsReadMore] = useState(false);
    const [isExpand, setIsExpand] = useState(false);


    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const toggleExpand = () => {
        setIsExpand(!isExpand);
    };
    const handleApply = (name, id) => {
        selecetdCompanyName(name)
        selecetdProjectId(id)
        setShowModal(true)
    }

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/projects/${slug}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setData(result?.project);
            setRecomProject(result?.related_projects);
            const groupedJobs = result?.project?.jobs?.reduce((acc, job) => {
                if (acc[job.personnel_type]) {
                    acc[job.personnel_type].push({ title: job.title, slug: job.slug });
                } else {
                    acc[job.personnel_type] = [{ title: job.title, slug: job.slug }];
                }
                return acc;
            }, {});

            if (groupedJobs) {
                console.log('groupedJobs', groupedJobs)
                setRequiredPositions(groupedJobs)
            }
            // const personnelDetails = result?.project[0]?.jobs || [];
            // const keyPersonnelNames = Array.isArray(personnelDetails)
            //     ? personnelDetails.map(person => person.name)
            //     : [];
            setPositions(result?.project?.jobs)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const copyURLToClipboard = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL);
        setShowCopyModal(false)
        toast.success('Link copied to clipboard!');
    };

    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : 'N/A';
        return formattedDeadline;
    }

    useEffect(() => {
        fetchData();

    }, [slug, token]);

    return (
        <BasicLayout>
            <ToastContainer />
            <ShowApplyModal
                show={showModal}
                type='project'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchData}
            />
            <ShowShareModal
                show={showCopyModal}
                handleClose={() => setShowCopyModal(false)}
                copyURLToClipboard={copyURLToClipboard}
            />
            <div className='inner-banner'>
                <div className='container'>
                    <h2>{data?.name}</h2>
                </div>
            </div>
            <div className="container project-details-container w-100 mt-2">
                <div className='row'>
                    <div className='col-md-12'>
                        {!token && (
                            <div class="alert alert-warning" role="alert">

                                <p className='mb-0'>
                                    To access full details and apply for jobs associated with this project, please
                                    <Link to="/sign-in"> sign in</Link> to your current account or
                                    <Link to="/sign-up"> sign up</Link> for a free account.

                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/* <div className={showModal || showCopyModal ? 'blurred-background' : ''}> */}
                <div className='detail-row'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='company-info shadow'>

                                <div className="company-logo" style={{ backgroundColor: data?.company?.logo ? '#fff' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                    {data?.company?.logo ? (
                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data?.company?.logo}`} alt="Profile picture" />
                                    ) : (
                                        <span className="text-uppercase"><strong>{data?.company?.company_name?.charAt(0)}</strong></span>
                                    )}
                                </div>
                                <button className='btn btn-share' onClick={() => setShowCopyModal(true)}><span class="material-symbols-outlined">share</span></button>
                                <div className='name'>

                                </div>
                                <h2>{data?.name}</h2>
                                <hr></hr>
                                <div className='project-info'>
                                    {/* <button className='btn btn-share' onClick={() => navigate(-1)}><span class="material-symbols-outlined">share</span></button> */}
                                    <label>Posted Date:</label>
                                    <p> <span>{convertDate(data?.created_at)}</span></p>
                                    <label>Deadline:</label>
                                    <p><span className="text-danger">{convertDate(data?.deadline)}</span></p>
                                    <label>Organization:</label>
                                    <p><span>{data?.company?.company_name}</span></p>
                                    <label>Sector:</label>
                                    <p><span>{data?.sector}</span></p>
                                    <label>Sub Sector:</label>
                                    <p><span>{data?.sub_sector}</span></p>
                                    <label>Category:</label>
                                    <p><span>{data?.category}</span></p>
                                    <label>Stage:</label>
                                    <p><span>{data?.stage}</span></p>
                                    {data?.budget && (
                                        <>
                                            <label>Project Cost: </label>
                                            <p>
                                                {token ?
                                                    <span className="text-primary">{data?.budget} {data?.currency}</span>
                                                    :
                                                    <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>
                                                }
                                            </p>
                                        </>
                                    )}
                                    <label>Locations:</label>
                                    <p><span>{data?.location}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className="card shadow project-details">
                                {/* project details area */}
                                <div className="card-body">
                                    <div className='card mt-1 mb-3 bg-white rounded'>
                                        <div className='card-header'><h2>Description</h2></div>
                                        <div className="card-body">
                                            <p>
                                                {token ? (
                                                    <div className='fs-6'>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: isExpand
                                                                ? data?.features
                                                                : data?.features?.length > 200
                                                                    ? `${data.features.slice(0, 200)}...`
                                                                    : data?.features,
                                                        }}></div>
                                                        {data?.features?.length > 200 && (
                                                            <button className="btn btn-link p-0" onClick={toggleExpand}>
                                                                {isExpand ? 'Read Less' : 'Read More'}
                                                            </button>
                                                        )}

                                                    </div>
                                                ) : (
                                                    <>
                                                        <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>
                                                        <span>
                                                            Please <Link to="/sign-in">sign in</Link> or{' '}
                                                            <Link to="/sign-up">create a free account</Link> to see this description.
                                                        </span>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    {requiredPositions && Object.keys(requiredPositions).length > 0 && (
                                        <>
                                            <div className='card mb-5 bg-white required-position rounded'>
                                                <div className='card-header'>
                                                    <h2>Required Positions</h2>
                                                </div>
                                                <div className="card-body">
                                                    {Object.entries(requiredPositions).map(([personnelType, jobs]) => (
                                                        <div key={personnelType} className='mt-2'>
                                                            <h6>Personnel Type: {personnelType === 'Both' ? 'Both National & International' : personnelType}</h6>
                                                            <ul>
                                                                {jobs.map((job, index) => (
                                                                    <li key={job.slug} className=''>
                                                                        <Link to={`/job/${job.slug}`}>{job.title}</Link>
                                                                        {index < jobs.length - 1 && ''}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {data?.company?.about && (
                                        <>
                                            <div className='card mb-3 bg-white rounded'>
                                                <div className='card-header'><h2>About Organization</h2></div>
                                                <div className="card-body">
                                                    <div className='fs-6'>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: isReadMore
                                                                ? data?.company?.about
                                                                : data?.company?.about?.length > 300
                                                                    ? `${data?.company?.about.slice(0, 300)}...`
                                                                    : data?.company?.about,
                                                        }}></div>
                                                        {data?.company?.about?.length > 300 && (
                                                            <button className="btn btn-link p-0" onClick={toggleReadMore}>
                                                                {isReadMore ? 'Read Less' : 'Read More'}
                                                            </button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}


                                    {/* {data.jd_image && (
                                        <div className="view view-cascade mt-3 mb-3">
                                            <img className="card-img-top" src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data.jd_image}`} alt="Card image cap" />
                                            <a href="#!">
                                                <div className="mask rgba-white-slight"></div>
                                            </a>
                                        </div>
                                    )} */}

                                    {token && data && data?.applications && (
                                        user && user?.user?.role_id === 4 ? (
                                            data?.applications.some(application => application?.user_id === user?.user.id) ? (
                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    <span className="text-success">Application already submitted</span>
                                                </div>
                                            ) : new Date(data?.deadline) < new Date() ? (
                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    <span className="text-danger">This project is no longer accepting applications</span>
                                                </div>
                                            ) : (
                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30" onClick={() => handleApply(data?.company?.company_name, data?.id)} >
                                                        Apply <i className="material-icons ml-2">arrow_forward</i>
                                                    </button>
                                                </div>
                                            )
                                        ) : null
                                    )}


                                    {/* <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <h4 className="pt-3 text-primary"><strong>{data?.name}</strong></h4>
                                    <h6>{data?.company?.company_name}</h6>
                                    <div className='p-2 d-flex justify-content-between'>
                                        {data?.created_at && (
                                            <div>
                                                <i className="material-icons fs-6" title="Posted on">schedule</i>
                                                <span className="fs-6 text-muted"> {formatDistanceToNow(new Date(data?.created_at))} ago</span>

                                            </div>
                                        )}
                                        <div className='px-3'>
                                            <i className="material-icons fs-6" title="Posted on">group</i>
                                            <span className="fs-6 text-success"> {data?.applications?.length} applicants</span>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h6 className="pt-2"><strong>Sector: </strong><span className="text-primary">{data?.sector}</span></h6>
                                            <h6 className="pt-2"><strong>Sub Sector: </strong><span className="text-primary">{data?.sub_sector}</span></h6>
                                        </div>

                                        <h6 className="pt-2"><strong>Deadline: </strong><span className="text-danger">{convertDate(data?.deadline)}</span></h6>
                                    </div>



                                    {data?.budget && (
                                        <h6 className="pt-2"><strong>Cost: </strong><span className="text-primary">{data?.budget}</span></h6>
                                    )}
                                    <h6 className="pt-2"><strong>Stage: </strong><span className="text-primary">{data?.stage}</span></h6>
                                    <h6 className="pt-2"><strong>Locations: </strong><span className="text-primary">{data?.location}</span></h6>
                                    <h6 className="pt-2"><strong>Personnel type: </strong><span className="text-primary">{data?.key_personnel_type === 'Both' ? 'National & International' : data?.key_personnel_type}
                                    </span></h6>
                                    <h6 className="pt-2"><strong>Required positions: </strong></h6>
                                    <div>
                                        {data?.key_personnel_details && data.key_personnel_details.map((personnel, index) => (
                                            <span className="badge text-bg-warning me-2 my-2 mb-3">{personnel.name}</span>
                                        ))}
                                    </div>
                                    <h6 className="pt-2"><strong>Description: </strong></h6>
                                    <div className='fs-6' dangerouslySetInnerHTML={{ __html: data?.features }}></div>
                                    {data?.company?.about && (
                                        <>
                                            <h6 className="pt-2"><strong>About the organization: </strong></h6>
                                            <div className='fs-6'>{data?.company?.about}</div>
                                        </>

                                    )}
                                    {data.jd_image && (
                                        <div className="view view-cascade mt-3 mb-3">
                                            <img className="card-img-top" src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data.jd_image}`} alt="Card image cap" />
                                            <a href="#!">
                                                <div className="mask rgba-white-slight"></div>
                                            </a>
                                        </div>
                                    )}

                                    {token && data && data.applications && (
                                        user && user?.user?.role_id === 4 ? (
                                            data.applications.some(application => application.user_id === user.user.id) ? (
                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    <span className="text-success">Application already submitted</span>
                                                </div>
                                            ) : (
                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30" onClick={() => handleApply(data?.company?.company_name, data?.id)} >
                                                        Apply <i className="material-icons ml-2">arrow_forward</i>
                                                    </button>
                                                </div>
                                            )
                                        ) : null
                                    )}

                                </div>

                            </div> */}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='releted-options'>
                    <div className='row'>
                        <h3 className='text-center'>Releated projects</h3>
                        {recomProject.length > 0 ?
                            <>
                                {recomProject.map((project, index) => (
                                    <>
                                        <div className="col-md-4 py-3" key={index}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row align-items-center company-title">
                                                        <div className="col-md-3">
                                                            <div className="profile-wrapper d-inline-flex align-items-center justify-content-center" style={{ backgroundColor: project?.company?.logo ? '#fff' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                                {project?.company?.logo ? (
                                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${project?.company?.logo}`} alt="Profile picture" className="img-fluid" />
                                                                ) : (
                                                                    <span className="text-uppercase first-letter"><strong>{project?.company?.company_name?.charAt(0)}</strong></span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <Link to={`/project/${project?.slug}`}><h2>{project?.name}</h2></Link>
                                                            <p>{project?.company?.company_name}</p>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='meta-info'>
                                                        <div className='row'>
                                                            <div className='col-md-4'>
                                                                <label>Closing on</label>
                                                                {convertDate(project?.deadline) && (
                                                                    <h6> {convertDate(project?.deadline)}</h6>
                                                                )}
                                                                <p>
                                                                    <i className="material-icons fs-6" title={project?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                    >info</i>
                                                                    <span> {project?.stage}</span>
                                                                </p>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <label>Sub Sector</label>
                                                                <h6 className="text-muted">{project?.sub_sector}</h6>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <label>Location</label>
                                                                <h6 className="text-muted">{project?.location}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </>
                                ))}
                            </>
                            :
                            <>
                                <div className="col-md-12 mt-5">
                                    <div className="card-shadow">
                                        <p className='text-center'>
                                            <span className="material-symbols-outlined fs-1">
                                                sentiment_dissatisfied
                                            </span>
                                        </p>
                                        <h5 className='text-center'>No Releated projects found </h5>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

        </BasicLayout>
    );
};

export default View;
