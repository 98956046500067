import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Slider from 'react-slick';
import { toast } from 'react-toastify';

const Jobs = ({ jobs, featuredjobs, token }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [savedJobs, setSavedJobs] = useState([]);
    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : '';
        return formattedDeadline;
    }
    const fetchSavedJobs = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-saved-jobs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,

                },
            });
            const result = await response.json();
            console.log('setSavedJobs', result)
            setSavedJobs(result?.saved_jobs || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSaveJob = async (id) => {
        try {
            const response = await fetch(`${apiUrl}api/toggle-saved-job/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                toast.success(data?.message);

                // Update savedJobs state in real time
                setSavedJobs((prevSavedJobs) =>
                    prevSavedJobs.includes(id)
                        ? prevSavedJobs.filter((jobId) => jobId !== id) // Remove the job ID if it was already saved
                        : [...prevSavedJobs, id] // Add the job ID if it wasn't saved
                );
            } else {
                const error = await response.json();
                console.error('Error toggling saved job:', error);
                toast.error('Failed to toggle save job. Please try again.');
            }
        } catch (err) {
            console.error('Error:', err);
            toast.error('Something went wrong. Please try again later.');
        }
    };
    useEffect(() => {
        fetchSavedJobs();
    }, [jobs]);
    return (
        <div className="container job-listing-wrapper">
            <h4 className="text-start mb-3">Featured Jobs</h4>
            <Slider {...featuredjobs}>
                {jobs?.map((job, index) => (
                    <div className="job-card" key={index}>
                        {/* Company Logo */}
                        <div className="logo-container text-center">
                            {job?.company?.logo ? (
                                <img
                                    src={`${apiUrl}${node === "production" ? "public/" : ""}storage/${job.company.logo}`}
                                    alt="Company Logo"
                                    className="company-logo-img"
                                />
                            ) : (
                                <div className="company-logo">
                                    {job?.company?.company_name?.charAt(0).toUpperCase()}
                                </div>
                            )}
                        </div>

                        {/* Job Information */}
                        <div className="job-details mt-2">
                            <Link to={`/job/${job.slug}`}>
                                <h6 className="text-primary">
                                    {job.title}
                                </h6>
                            </Link>
                            <p className="mb-1 company text-truncate">{job.location}</p>
                            <p className="mb-1 company text-truncate">{job.company.company_name}</p>
                            <p className="mb-1">
                                <span className="badge bg-secondary text-white">{job.sector}</span>
                                <span className="ms-1 badge bg-secondary text-white">{job.stage}</span>
                                <span className="ms-1 badge bg-secondary text-white">{job.salary} {job.currency}</span>
                            </p>
                            <p className="text-muted">Posted on {convertDate(job.created_at)}</p>

                            {/* Save Job Button and Job Stage */}
                            {/* {savedJobs.includes(job?.id) ?
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <button className="btn-primary" onClick={() => handleSaveJob(job?.id)}>
                                        <i className="bi bi-bookmark"></i> Saved
                                    </button>
                                </div>
                                :
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <button className="btn-save" onClick={() => handleSaveJob(job?.id)}>
                                        <i className="bi bi-bookmark"></i> Save job
                                    </button>
                                </div>
                            } */}
                           
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="col-md-12 d-flex justify-content-center mt-5 mb-2">
                <Link to={"/all-jobs"}>
                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill mx-2">
                        Explore More Jobs <i className="material-icons ml-1">arrow_forward</i>
                    </button>
                </Link>
            </div>
        </div>




    )
}

export default Jobs