/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";


// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import OrganizationSignUp from "layouts/authentication/sign-up/employer";
import ProfSignUp from "layouts/authentication/sign-up/seeker";
import ThankYou from "layouts/pages/ThankYou";
import TermsCondition from "layouts/pages/TermsCondition";
import PrivacyPolicy from "layouts/pages/PrivacyPolicy"
import HomePage from "layouts/homepage"
import ForgotPassword from "layouts/authentication/forgot-password/cover"
import ResetPassword from "layouts/authentication/reset-password"
import CreateRole from "layouts/authentication/roles/create"
import EditRole from "layouts/authentication/roles/edit"
import ViewEmployer from "layouts/employer/view"
import CreateProject from 'layouts/employer/components/projects/create'
import EditProject from 'layouts/employer/components/projects/edit'
import ViewApplicants from 'layouts/employer/components/projects/viewApplicants'
import ViewExperts from 'layouts/employer/components/projects/viewExperts'
import ApplicantDetails from 'layouts/employer/components/projects/applicantDetails'
import ExpertDetails from 'layouts/employer/components/projects/expertDetails'
import AllProjects from 'layouts/projects'
import ProjectDetails from 'layouts/projects/view'
import JobDetails from 'layouts/jobs/view'
import AllJobs from 'layouts/jobs'
import AppliedJobs from 'layouts/jobs/AppliedJobs'
import MyProfile from "layouts/dashboard/profile";
import UserProfile from "layouts/seeker/components/profile";
import Professional from "layouts/professionals/index"
import ProfessionalDetails from "layouts/professionals/view"
import Organizations from "layouts/organizations/index"
import OrganizationDetails from "layouts/organizations/view"
import CreateJob from "layouts/jobs/create"
import EditJob from "layouts/jobs/edit"
import PostedJobs from "layouts/jobs/PostedJobs"
import ViewProject from "layouts/admin/projects/view"
import EditProjectDetails from "layouts/admin/projects/edit"
import ViewJob from "layouts/admin/jobs/view"
import EditJobDetails from "layouts/admin/jobs/edit"
import MyProject from "layouts/employer/components/projects"
import Contact from "layouts/contact/index"
import News from "layouts/news/index"
import ViewNews from "layouts/news/view"
import ProfileCompletion from './layouts/ProfileCompletion/index';
import LandingPage from './layouts/landingPage/index';
import Error404 from 'layouts/errors/error404'

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../src/store/slices/authSlice';
import { selectAuth } from '../src/store/slices/authSlice';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute from "./ProtectedRoute";
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from "react-cookie-consent";
import AccountDelete from "layouts/pages/AccountDelete";
import RecoverAccount from "layouts/pages/RecoverAccount";
import AboutUs from "layouts/pages/AboutUs";
import Career from "layouts/pages/Career";
import Faq from "layouts/pages/Faq";
import SiteMap from "layouts/pages/SiteMap";
import PushNotification from "./PushNotification";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [cookies] = useCookies(['token']);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const tokendispatch = useDispatch();
  const { token, user } = useSelector(selectAuth);


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);



  const fetchUserData = useCallback(async (newToken) => {
    try {
      const response = await fetch(`${apiUrl}api/profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${newToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const { token, user } = data;
      if (user && token) {
        tokendispatch(login({ user, token }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [apiUrl, tokendispatch]); // Include all dependencies used inside the function.
  
  useEffect(() => {
    if (cookies.token) {
      fetchUserData(cookies.token);
    }
  }, [cookies.token, fetchUserData]);
  
  // //Fetch data when page load 
  // useEffect(() => {
  //   fetchUserData(cookies.token);
  // }, [cookies.token]);

  const getRoutes = (allRoutes) =>
    allRoutes.reduce((result, route) => {
      if (route.collapse) {
        return result.concat(getRoutes(route.collapse));
      }

      if (route.route) {
        const token = cookies['token'];
        const isAuthenticated = token || null;
        if (user) {
          const can = (permission) => (user?.user && user?.user.permissions) ? user?.user.permissions.find((p) => p === permission) : false;
          // if (isAuthenticated && can(`view ${route.key}`) || (user?.user?.role_id === 1)) {
          //   result.push(<Route exact path={route.route} element={route.component} key={route.key} user={user} />);
          // }
          if (
            (isAuthenticated && can(`view ${route.key}`)) || 
            (user?.user?.role_id === 1)
          ) {
            result.push(
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key}
                user={user}
              />
            );
          }          
        }

      }

      return result;
    }, []);

    const handleAccept = () => {
      console.log("Cookies accepted!");
    };
  
    const handleDecline = () => {
      console.log("Cookies declined!");
    };

  const configsButton = (
    <MDBox
      /* display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem" */
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <PushNotification />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        declineButtonText="I decline"
        cookieName="Proxtal webportal"
        expires={150}
        enableDeclineButton
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        <span>We use cookies to help improve your user experience. By using our site, you agree to the terms of our <a className="text-white" href="/privacy-policy" target="_blank"> Privacy Policy</a>.{" "}</span>
        
      </CookieConsent>
      <CssBaseline />
      {user && user?.user?.role_id === 1 && layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Proxtal"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            user={user}
          />
          <Configurator />
          {configsButton}
        </>
      )}

      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route exact path='*' element={<Error404 />} />
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/sign-in' element={ <SignIn />}/>
        <Route exact path='/sign-up' element={ <SignUp />} />
        <Route exact path='/sign-up-as-organization' element={ <OrganizationSignUp />} />
        <Route exact path='/sign-up-as-professional' element={ <ProfSignUp />} />
        <Route exact path='/thank-you' element={<ThankYou />} />
        <Route exact path='/terms-and-conditions' element={<TermsCondition />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/account-deleted' element={<AccountDelete />} />
        <Route exact path='/account-recover' element={<RecoverAccount />} />
        <Route exact path='/support' element={<Contact />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/career' element={<Career />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/site-map' element={<SiteMap />} />
        <Route exact path='/forgot-password' element={user ? <Navigate to="/" /> : <ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
        <Route exact path='/all-projects' element={<AllProjects />} />
        <Route exact path='/project/:slug' element={<ProjectDetails />} />
        <Route exact path='/all-jobs' element={<AllJobs />} />
        <Route exact path='/job/:slug' element={<JobDetails />} />
        <Route exact path='/professionals' element={<Professional />} />
        <Route exact path='/profile/:slug' element={<ProfessionalDetails />} />
        <Route exact path='/organizations' element={<Organizations />} />
        <Route exact path='/organization/:slug' element={<OrganizationDetails />} />

        {/* News */}
        <Route exact path='/news-feed' element={<News />} />
        <Route exact path='/news-feed/:slug' element={<ViewNews />} />

        <Route element={<ProtectedRoute token={token} />}>

          <Route exact path='/my-profile' element={<MyProfile />} />
          <Route exact path='/profile' element={<UserProfile />} />
          {/* Roles & Permissions */}
          <Route exact path='/create-role' element={<CreateRole />} />
          <Route exact path='/edit-role/:id' element={<EditRole />} />

          {/* Employers */}
          <Route exact path='/employers/view/:id' element={<ViewEmployer />} />

          {/* Projects */}
          <Route exact path='/my-projects' element={<MyProject />} />
          <Route exact path='/create-project' element={<CreateProject />} />
          <Route exact path='/edit-project/:slug' element={<EditProject />} />
          <Route exact path='/project/:slug/applicants' element={<ViewApplicants />} />
          <Route exact path='/project/:projectSlug/applicants/:slug' element={<ApplicantDetails />} />
          <Route exact path='/project/:slug/matched-professionals' element={<ViewExperts />} />
          <Route exact path='/project/:slug/matched-professionals/:slug' element={<ExpertDetails />} />
          <Route exact path='/applied-jobs' element={<AppliedJobs />} />
          <Route exact path='/add-new-job' element={<CreateJob />} />
          <Route exact path='/edit-job/:slug' element={<EditJob />} />
          <Route exact path='/posted-jobs' element={<PostedJobs />} />

          <Route exact path='/job/:slug/applicants' element={<ViewApplicants />} />
          <Route exact path='/job/:projectSlug/applicants/:slug' element={<ApplicantDetails />} />
          <Route exact path='/job/:slug/matched-professionals' element={<ViewExperts />} />
          <Route exact path='/job/:slug/matched-professionals/:slug' element={<ExpertDetails />} />
          <Route exact path='/my-dashboard' element={<LandingPage />} />

          {/* For Admin */}
          <Route exact path='/project/:slug/view' element={<ViewProject />} />
          <Route exact path='/project/:slug/edit' element={<EditProjectDetails />} />

          <Route exact path='/job/:slug/view' element={<ViewJob />} />
          <Route exact path='/job/:slug/edit' element={<EditJobDetails />} />

          <Route exact path='/profile-completion' element={<ProfileCompletion />} />
          

        </Route>
        
      </Routes>
    </ThemeProvider>
  );
}
