import { useEffect } from 'react';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { selectAuth } from './store/slices/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PushNotification = () => {
    const { user } = useSelector(selectAuth);

    const handleClick = (link) => {
        window.open(link, '_blank'); // Open the link in a new tab
    };

    useEffect(() => {
        console.log('Initializing Pusher...'); // Debug log

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            encrypted: true,
        });

        console.log('Pusher config:', {
            key: process.env.REACT_APP_PUSHER_KEY,
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        }); // Debug log

        const channel = pusher.subscribe('my-channel');

        channel.bind('pusher:subscription_succeeded', () => {
            console.log('Successfully subscribed to channel'); // Debug log
        });

        channel.bind('my-event', function (data) {
            console.log('Received event data:', data); // Debug log

            if (data?.message) {
                if (user?.user?.sectors.includes(data.sector)) {
                    // Use toast to display the custom JSX
                    toast(
                        <div style={{ textAlign: "center" }}>
                            <p>{data.message}</p>
                            <button
                                className='btn btn-primary btn-sm'
                                style={{
                                    padding: "8px 16px",
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                }}
                                onClick={() => handleClick(data.link)}
                            >
                                View
                            </button>
                        </div>,
                        {
                            className: "toast-message",
                            position: "top-center", // Center the toast
                            autoClose: 5000, // Close after 5 seconds
                            closeOnClick: false, // Disable toast closing on background click
                        }
                    );
                }
            }
        });

        pusher.connection.bind('connected', () => {
            console.log('Connected to Pusher'); // Debug log
        });

        pusher.connection.bind('error', (err) => {
            console.error('Pusher connection error:', err); // Debug log
        });

        return () => {
            console.log('Cleaning up Pusher...'); // Debug log
            pusher.unsubscribe('my-channel');
        };
    }, [user]);

    return null;
};

export default PushNotification;
