import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress, Icon, Autocomplete, TextField } from '@mui/material';
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import ShowApplyModal from 'layouts/projects/ShowApplyModal';
import { useLocation } from 'react-router-dom';
import CustomLoader from 'components/CustomLoader'

const Index = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, setSelectedCompanyName] = useState('');
    const [projectId, setSelectedProjectId] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedStage, setSelectedStage] = useState('');
    const [selectPersonnelType, setSelectPersonnelType] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [selectedDate, setSelectedDate] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [searchkeyWord, setSearchKeyword] = useState('');
    const [positions, setPositions] = useState([]);
    const location = useLocation();
    const [savedJobs, setSavedJobs] = useState([]);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [selectedLocationCheckboxes, setSelectedLocationCheckboxes] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedSalaryCheckboxes, setSelectedSalaryCheckboxes] = useState([]);
    const salaryRanges = ['0-3 Lakh', '3-6 Lakh', '6-10 Lakh', '10-15 Lakh'];
    const [selectedPositionTypeCheckboxes, setSelectedPositionTypeCheckboxes] = useState([]);
    const [searchLocation, setSearchLocation] = useState('');
    const positionTypes = [
        'Full-time',
        'Part-time',
        'Contract',
        'Permanent',
        'Temporary',
        'Seasonal',
        'Intermittent',
        'Internship'
    ];
    const [selectedCategoryCheckboxes, setSelectedCategoryCheckboxes] = useState([]);
    const categories = [
        'Goods',
        'Works',
        'Consulting Services',
        'Other Non-Consulting Services'
    ];
    const currencyCodes = [
        "Select Currency", "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
    ];


    const handleSelectCurrency = (code) => {
        setSelectedCurrency(code);
    };

    const navigate = useNavigate()
    const dispatch = useDispatch();

    function createSlug(text) {
        // Replace all spaces with hyphens and convert to lowercase
        return text.trim().toLowerCase().replace(/\s+/g, '-');
    }
    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id, positionName) => {
        setSelectedCompanyName(name)
        setSelectedProjectId(id)
        setShowModal(true)
        setPositions(positionName)
    }
    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : '';
        return formattedDeadline;
    }
    const convertDateToDays = (date) => {
        if (!date) return '';

        const parsedDate = parseISO(date);
        return formatDistanceToNow(parsedDate, { addSuffix: true });
    }

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorName = event.target.value;
        setSelectedSector(sectorName);
    };

    const handleStageChange = (event) => {
        const value = event.target.value;
        setSelectedStage(value);
    };

    const handleDateChange = (event) => {
        const value = event.target.value;
        setSelectedDate((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleSearchLocationChange = (event) => {
        setSearchLocation(event.target.value);
        setCurrentPage(1);
    };

    // const handleLocationCheckboxChange = (event) => {
    //     const value = event.target.value;
    //     setSelectedLocationCheckboxes((prev) =>
    //         prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    //     );
    // };

    const handleSectorCheckboxChange = (event) => {
            const value = event.target.value;
            setSelectedSector((prev) =>
                prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
            );
        };

    const handlePositionTypeCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedPositionTypeCheckboxes((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleSalaryCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedSalaryCheckboxes((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleCategoryCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedCategoryCheckboxes((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handlePersonnelTypeChange = (event) => {
        const value = event.target.value;
        setSelectPersonnelType((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        fetchCountryStateList();
        fetchSectorsData();
        fetchFilteredProjects(); // Fetch data initially with filtering
        if (token) {
            fetchSavedJobs();
        }
    }, [token, currentPage]);

    const fetchFilteredProjects = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            console.log("selectedPositionTypeCheckboxes", selectedPositionTypeCheckboxes)

            // Job title
            if (searchTerm) {
                queryParams.append('title', searchTerm);
            }

            // Sector
            if (selectedSector) {
                queryParams.append('selectedSector', selectedSector.join(','));
            }

            // Locations
            if (searchLocation) {
                queryParams.append('locations', searchLocation);
            }

            // categories
            if (selectedCategoryCheckboxes.length > 0) {
                queryParams.append('selectedCategory', selectedCategoryCheckboxes.join(','));
            }

            // Position types
            if (selectedPositionTypeCheckboxes.length > 0) {
                queryParams.append('position_type', selectedPositionTypeCheckboxes.join(','));
            }

            // Salary ranges
            if (selectedSalaryCheckboxes.length > 0) {
                queryParams.append('salary_range', selectedSalaryCheckboxes.join(','));
            }

            // Currency
            if (selectedCurrency) {
                queryParams.append('currency', selectedCurrency);
            }

            // Posted date
            if (selectedDate.length > 0) {
                queryParams.append('posted_date', selectedDate.join(','));
            }

            // Personnel type
            if (selectPersonnelType.length > 0) {
                queryParams.append('personnel_type', selectPersonnelType.join(','));
            }

            // Stage
            if (selectedStage) {
                queryParams.append('stage', selectedStage.join(','));
            }


            // Pagination
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/jobs?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();
            if (result) {
                setSearchKeyword(result?.searchkeyWord || '');
                setData(result?.data?.data || []);
                setCurrentPage(result.data?.current_page);
                setTotalPages(result.data?.last_page);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }
    const fetchSectorsData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSavedJobs = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-saved-jobs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,

                },
            });
            const result = await response.json();
            console.log('setSavedJobs', result)
            setSavedJobs(result?.saved_jobs || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSaveJob = async (id) => {
        try {
            const response = await fetch(`${apiUrl}api/toggle-saved-job/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                toast.success(data?.message);

                // Update savedJobs state in real time
                setSavedJobs((prevSavedJobs) =>
                    prevSavedJobs.includes(id)
                        ? prevSavedJobs.filter((jobId) => jobId !== id) // Remove the job ID if it was already saved
                        : [...prevSavedJobs, id] // Add the job ID if it wasn't saved
                );
            } else {
                const error = await response.json();
                console.error('Error toggling saved job:', error);
                toast.error('Failed to toggle save job. Please try again.');
            }
        } catch (err) {
            console.error('Error:', err);
            toast.error('Something went wrong. Please try again later.');
        }
    };

    // Function to open the modal
    const handleViewMoreClick = () => {
        setShowLocationModal(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowLocationModal(false);
    };

    return (
        <BasicLayout>
            <ToastContainer />
            {/* {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )} */}
            <ShowApplyModal
                show={showModal}
                type='job'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchFilteredProjects}
            />
            <div className='inner-banner'>
                <div className='container'>
                    <h2>All Jobs</h2>
                </div>
            </div>



            <div className={showModal ? 'blurred-background' : ''}>
                <div className="container job-listing-wrapper mt-2">
                    <div className='row' >
                        <div className='col-md-3 sidebar position-sticky mb-3 shadow-md border-2 rounded-3' >
                            {/* Show Filter Button for Mobile Only */}
                            <button
                                className="btn btn-warning d-md-none mb-3"
                                onClick={toggleFilters}
                            >
                                {showFilters ? 'Hide Filters' : 'Show Filters'}
                            </button>

                            {/* Filter Sidebar - Always visible on desktop/tablet, toggled on mobile */}
                            <div className={`project-filters ${showFilters ? '' : 'd-none d-md-block'}`}>
                                <div className='row'>
                                    {/* Search by Location Accordion */}
                                    {/* <div className='form-group col-md-12'>
                                        <div className="accordion" id="locationAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Search by Location
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#locationAccordion">
                                                    <div className="accordion-body" >
                                                        {stateCountryList.slice(0, 5).map((location, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={location}
                                                                        checked={selectedLocationCheckboxes.includes(location)}
                                                                        onChange={handleLocationCheckboxChange}
                                                                        className='me-2'
                                                                    />
                                                                    {location}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLocations" aria-controls="offcanvasLocations">
                                                            View More
                                                        </button>
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasLocations" aria-labelledby="offcanvasLocationsLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasLocationsLabel">All Locations</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {stateCountryList.map((location, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={location}
                                                                                checked={selectedLocationCheckboxes.includes(location)}
                                                                                onChange={handleLocationCheckboxChange}
                                                                                className='me-2'
                                                                            />
                                                                            {location}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* Search by Sector Section */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="sectorAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSector">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSector" aria-expanded="true" aria-controls="collapseSector">
                                                        Search by Sector
                                                    </button>
                                                </h2>
                                                <div id="collapseSector" className="accordion-collapse collapse show" aria-labelledby="headingSector" data-bs-parent="#sectorAccordion">
                                                    <div className="accordion-body">
                                                        {/* Default Sector Checkboxes */}
                                                        {sectors.slice(0, 2).map((sector, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={sector.name}
                                                                        checked={selectedSector.includes(sector.name)}
                                                                        onChange={handleSectorCheckboxChange}
                                                                        className='me-2'
                                                                    />
                                                                    {sector.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* View More Button for Sectors */}
                                                        <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSectors" aria-controls="offcanvasSectors">
                                                            View More
                                                        </button>
                                                        {/* Offcanvas for Sectors */}
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSectors" aria-labelledby="offcanvasSectorsLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasSectorsLabel">All Sectors</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {sectors.map((sector, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={sector.name}
                                                                                checked={selectedCategoryCheckboxes.includes(sector.name)}
                                                                                onChange={handleSectorCheckboxChange}
                                                                                className='me-2'
                                                                            />
                                                                            {sector.name}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* New Position Type Filter */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="positionTypeAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingPositionType">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePositionType" aria-expanded="true" aria-controls="collapsePositionType">
                                                        Search by Position Type
                                                    </button>
                                                </h2>
                                                <div id="collapsePositionType" className="accordion-collapse collapse show" aria-labelledby="headingPositionType" data-bs-parent="#positionTypeAccordion">
                                                    <div className="accordion-body">
                                                        {/* Default Position Type Checkboxes */}
                                                        {positionTypes.slice(0, 2).map((type, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={type}
                                                                        checked={selectedPositionTypeCheckboxes.includes(type)}
                                                                        onChange={handlePositionTypeCheckboxChange}
                                                                        className='me-2'
                                                                    />
                                                                    {type}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* View More Button for Position Types */}
                                                        <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPositionTypes" aria-controls="offcanvasPositionTypes">
                                                            View More
                                                        </button>
                                                        {/* Offcanvas for Position Types */}
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasPositionTypes" aria-labelledby="offcanvasPositionTypesLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasPositionTypesLabel">All Position Types</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {positionTypes.map((type, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={type}
                                                                                checked={selectedPositionTypeCheckboxes.includes(type)}
                                                                                onChange={handlePositionTypeCheckboxChange}
                                                                                className='me-2'
                                                                            />
                                                                            {type}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* New Salary Filter */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="salaryAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        Search by Salary
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salaryAccordion">
                                                    <div className="accordion-body">
                                                        {/* Dropdown for Currency Codes */}
                                                        <div className="dropdown float-end">
                                                            <button className="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" style={{ backgroundColor: '#f8f9fa', color: '#6c757d', border: '1px solid #8f9397', fontSize: '12px', transition: 'all 0.3s ease-in-out', cursor: 'pointer' }}>
                                                                {selectedCurrency || 'Select Currency'}
                                                            </button>
                                                            <ul className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                                {currencyCodes.map((code, index) => (
                                                                    <li key={index}>
                                                                        <a className="dropdown-item" onClick={() => handleSelectCurrency(code)}>
                                                                            {code}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        {/* Default Salary Checkboxes */}
                                                        {salaryRanges.slice(0, 2).map((salary, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={salary}
                                                                        checked={selectedSalaryCheckboxes.includes(salary)}
                                                                        onChange={handleSalaryCheckboxChange}
                                                                        className='me-2'
                                                                    />
                                                                    {salary}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* View More Button for Salaries */}
                                                        <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSalaries" aria-controls="offcanvasSalaries">
                                                            View More
                                                        </button>
                                                        {/* Offcanvas for Salaries */}
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSalaries" aria-labelledby="offcanvasSalariesLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasSalariesLabel">All Salary Ranges</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {salaryRanges.map((salary, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={salary}
                                                                                checked={selectedSalaryCheckboxes.includes(salary)}
                                                                                onChange={handleSalaryCheckboxChange}
                                                                                className='me-2'
                                                                            />
                                                                            {salary}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* New Category Filter */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="categoryAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingCategory">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCategory" aria-expanded="true" aria-controls="collapseCategory">
                                                        Search by Category
                                                    </button>
                                                </h2>
                                                <div id="collapseCategory" className="accordion-collapse collapse show" aria-labelledby="headingCategory" data-bs-parent="#categoryAccordion">
                                                    <div className="accordion-body">
                                                        {/* Default Category Checkboxes */}
                                                        {categories.slice(0, 2).map((category, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={category}
                                                                        checked={selectedCategoryCheckboxes.includes(category)}
                                                                        onChange={handleCategoryCheckboxChange}
                                                                        className='me-2'
                                                                    />
                                                                    {category}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* View More Button for Categories */}
                                                        <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCategories" aria-controls="offcanvasCategories">
                                                            View More
                                                        </button>
                                                        {/* Offcanvas for Categories */}
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasCategories" aria-labelledby="offcanvasCategoriesLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasCategoriesLabel">All Categories</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {categories.map((category, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={category}
                                                                                checked={selectedCategoryCheckboxes.includes(category)}
                                                                                onChange={handleCategoryCheckboxChange}
                                                                                className='me-2'
                                                                            />
                                                                            {category}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Search by Stage Section */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="stageAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingStage">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseStage" aria-expanded="true" aria-controls="collapseStage">
                                                        Search by Stage
                                                    </button>
                                                </h2>
                                                <div id="collapseStage" className="accordion-collapse collapse show" aria-labelledby="headingStage" data-bs-parent="#stageAccordion">
                                                    <div className="accordion-body">
                                                        {['Current', 'Proposal'].map((stage, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={stage}
                                                                        checked={selectedStage.includes(stage)}
                                                                        onChange={(event) => {
                                                                            const value = event.target.value;
                                                                            setSelectedStage((prev) =>
                                                                                prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
                                                                            );
                                                                        }}
                                                                        className='me-2'
                                                                    />
                                                                    {stage}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Search by Personnel Type Section */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="personnelTypeAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingPersonnelType">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePersonnelType" aria-expanded="true" aria-controls="collapsePersonnelType">
                                                        Personnel Type
                                                    </button>
                                                </h2>
                                                <div id="collapsePersonnelType" className="accordion-collapse collapse show" aria-labelledby="headingPersonnelType" data-bs-parent="#personnelTypeAccordion">
                                                    <div className="accordion-body">
                                                        {['National', 'International'].map((type, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={type}
                                                                        checked={selectPersonnelType.includes(type)}
                                                                        onChange={handlePersonnelTypeChange}
                                                                        className='me-2'
                                                                    />
                                                                    {type}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Search by Posted Timeframe Section */}
                                    <div className='form-group col-md-12'>
                                        <div className="accordion" id="postedDateAccordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingPostedDate">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePostedDate" aria-expanded="true" aria-controls="collapsePostedDate">
                                                        Search by Posted Timeframe
                                                    </button>
                                                </h2>
                                                <div id="collapsePostedDate" className="accordion-collapse collapse show" aria-labelledby="headingPostedDate" data-bs-parent="#postedDateAccordion">
                                                    <div className="accordion-body">
                                                        {/* Show only the first two options initially */}
                                                        {['All time', 'Recent'].map((timeframe, index) => (
                                                            <div key={index}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={timeframe}
                                                                        checked={selectedDate.includes(timeframe)}
                                                                        onChange={(event) => {
                                                                            const value = event.target.value;
                                                                            setSelectedDate((prev) =>
                                                                                prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
                                                                            );
                                                                        }}
                                                                        className='me-2'
                                                                    />
                                                                    {timeframe}
                                                                </label>
                                                            </div>
                                                        ))}
                                                        {/* View More Button */}
                                                        <button className="btn btn-link p-0 mt-2" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPostedDate" aria-controls="offcanvasPostedDate">
                                                            View More
                                                        </button>
                                                        {/* Offcanvas for Posted Timeframe */}
                                                        <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasPostedDate" aria-labelledby="offcanvasPostedDateLabel">
                                                            <div className="offcanvas-header">
                                                                <h5 className="offcanvas-title" id="offcanvasPostedDateLabel">All Posted Timeframes</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                {/* Show all four options in the offcanvas */}
                                                                {['All time', 'Recent', 'Last 7 days', 'Last 30 days'].map((timeframe, index) => (
                                                                    <div key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={timeframe}
                                                                                checked={selectedDate.includes(timeframe)}
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value;
                                                                                    setSelectedDate((prev) =>
                                                                                        prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
                                                                                    );
                                                                                }}
                                                                                className='me-2'
                                                                            />
                                                                            {timeframe}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12 d-flex gap-1 mt-4 mt-md-0 mb-3'>
                                        <button className="btn btn-warning w-50 rounded-3" type="button" onClick={fetchFilteredProjects}>
                                            Apply
                                        </button>
                                        <button
                                            className="btn btn-secondary w-50 rounded-3"
                                            onClick={() => {
                                                setSelectedLocations([]);
                                                function createSlug(name) {
                                                    return name.toLowerCase().replace(/\s+/g, '-');
                                                }
                                                setSelectedSector('');
                                                setSelectedStage('');
                                                setSelectedDate('');
                                                setSearchTerm('');
                                                fetchFilteredProjects();
                                                navigate('/all-jobs');
                                            }}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='banner text-center'>
                                <div className="d-flex w-100" style={{ padding: "20px" }}>
                                    <div className="d-flex align-items-center shadow-sm border border-2" style={{ padding: "5px 10px" }}>
                                        {/* Job Title Input */}
                                        <div className="d-flex align-items-center flex-grow-1">
                                            <i className="bi bi-search text-muted me-2"></i>
                                            <input
                                                type="text"
                                                className="form-control border-0 shadow-none"
                                                placeholder={`${isMobile ? 'Job Title' : 'Enter a job title'}`}
                                                style={{ outline: "none" }}
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                            />
                                        </div>

                                        <div className="vr mx-2"></div>

                                        <div className="d-flex align-items-center flex-grow-1">
                                            <i className="bi bi-geo-alt text-muted me-2"></i>
                                            <input
                                                type="text"
                                                className="form-control border-0 shadow-none"
                                                placeholder="Location"
                                                style={{ outline: "none" }}
                                                value={searchLocation}
                                                onChange={handleSearchLocationChange}
                                            />
                                        </div>

                                        <button
                                            className="btn btn-warning rounded-circle d-flex align-items-center justify-content-center custom-search-btn"
                                            style={{ width: "40px", height: "40px" }}
                                            onClick={fetchFilteredProjects}
                                        >
                                            <i className="bi bi-arrow-right text-white"></i>
                                        </button>

                                    </div>
                                </div>
                            </div>

                            {/* <div className='row mt-3'>
                                <div className='col-md-4'>
                                    <input
                                        type="text"
                                        className="form-control rounded-3"
                                        placeholder="Search by position"
                                        aria-describedby="button-addon2"
                                        value={searchTerm}
                                        onChange={handleSearchTermChange}
                                    />
                                </div>
                                <div className='col-md-4 mt-4 mt-md-0'>
                                    <select
                                        className="form-control rounded-3"
                                        id="type"
                                        name="type"
                                        value={selectedSector}
                                        onChange={(event) => {
                                            handleSectorChange(event);
                                        }}
                                    >
                                        <option value="">Select Sector</option>
                                        {sectors.map((sector) => (
                                            <option key={sector.name} value={sector.name}>{sector.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='col-md-3 d-flex gap-1 mt-4 mt-md-0'>
                                    <button className="btn btn-success w-50 rounded-3" type="button" onClick={fetchFilteredProjects}>
                                        Search
                                    </button>
                                    <button
                                        className="btn btn-warning w-50 rounded-3"
                                        onClick={() => {
                                            setSelectedLocations([]);
                                            function createSlug(name) {
                                                return name.toLowerCase().replace(/\s+/g, '-');
                                            }
                                            setSelectedSector('');
                                            setSelectedStage('');
                                            setSelectedDate('');
                                            setSearchTerm('');
                                            fetchFilteredProjects();
                                            navigate('/all-jobs');
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div> */}

                            {data && data.length > 0 ? (
                                <div className='row mt-4'>
                                    {data.map((job, index) => (
                                        <div className="col-md-12 mb-3" key={index}>
                                            <div className="job-listing-card d-flex flex-column flex-md-row">
                                                {/* Company Logo */}
                                                {job?.company?.logo ? (
                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${job?.company?.logo}`}
                                                        alt="Company Logo"
                                                        className="job-logo img-fluid mt-1"
                                                    />
                                                ) : (
                                                    <div className="letter-cap me-3">{job?.company?.company_name?.charAt(0)}</div>
                                                )}

                                                {/* Job Details */}
                                                <div className="job-details flex-grow-1">
                                                    {new Date(job?.deadline) >= new Date() && (
                                                        <span className="badge early-applicant mt-md-0 mt-2">Be an early applicant</span>
                                                    )}
                                                    <Link to={`/job/${job?.slug}`} className="text-decoration-none text-dark">
                                                        <h5 className="job-title text-primary">{job?.title}</h5>
                                                    </Link>
                                                    <p className="job-company text-black text-muted">{job?.company?.company_name}</p>
                                                    <p className="job-location text-muted">{job?.location}</p>

                                                    {/* Job Badges */}
                                                    <div className="job-meta flex-wrap">
                                                        <span className="badge job-badge rounded-pill">{job?.job_type}</span>
                                                        <span className="badge job-badge rounded-pill">{job?.sector}</span>
                                                        <span className="badge job-badge rounded-pill">{job?.sub_sector}</span>
                                                        {isMobile ?
                                                            <></>
                                                            :
                                                            <>
                                                                <span className="badge job-badge rounded-pill">{job?.personnel_type == 'Both' ? 'National & International' : job?.personnel_type}</span>
                                                                <span className="badge job-badge rounded-pill">{job?.category}</span>
                                                            </>
                                                        }

                                                    </div>

                                                    <div className={isMobile ? "" : "d-flex"}>
                                                        <div className={isMobile ? "job-meta" : "job-meta"}>
                                                            <p className="job-posted">
                                                                <i className="bi bi-calendar-check-fill text-muted me-2"></i>Posted on {convertDateToDays(job?.created_at)}
                                                            </p>
                                                            {convertDate(job?.deadline) && (
                                                                <p className="job-posted">
                                                                    <i className="bi bi-clock-fill text-muted me-2"></i>Closing on {convertDate(job?.deadline)}
                                                                </p>
                                                            )}
                                                            <p className='job-posted'>
                                                                <i className="bi bi-info-circle-fill text-muted me-2" title={job?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                ></i>  {job?.stage}
                                                            </p>
                                                        </div>

                                                        {/* Apply Button */}
                                                        {token && user?.user?.role_id === 4 && (
                                                            <div className={isMobile ? 'd-flex justify-content-center mt-5' : 'apply-job'}>
                                                                {job?.applications?.some(application => application.user_id === user.user.id && application?.position === job?.title) ? (
                                                                    <span className="text-success">Application already submitted</span>
                                                                ) : new Date(job?.deadline) < new Date() ? (
                                                                    <span className="text-danger">This job is no longer accepting applications</span>
                                                                ) : (
                                                                    <button className="btn btn-warning w-md-0 w-100" onClick={() => handleApply(job?.company?.company_name, job?.id, job?.title)}>
                                                                        <i className="bi bi-arrow-right-circle-fill"></i> Apply Now
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>


                                                </div>

                                                {/* Save Job Button */}
                                                <div className="save-job btn-sm btn-warning">
                                                    <i className="bi bi-bookmark"></i>
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    ))}
                                </div>

                            ) : (
                                <h2 className='text-center mt-3'>No Jobs found</h2>
                            )}
                            <div className='container pagination'>
                                {/* Pagination */}
                                {totalPages > 1 && (
                                    <MDPagination size="medium" className="page-list">
                                        {currentPage > 1 && (
                                            <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                                                <Icon>keyboard_arrow_left</Icon>
                                            </MDPagination>
                                        )}
                                        {Array.from({ length: totalPages }, (_, i) => (
                                            <MDPagination item
                                                key={i + 1}
                                                active={currentPage === i + 1}
                                                onClick={() => handlePageChange(i + 1)}
                                            >
                                                {i + 1}
                                            </MDPagination>
                                        ))}
                                        {currentPage < totalPages && (
                                            <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                                                <Icon>keyboard_arrow_right</Icon>
                                            </MDPagination>
                                        )}
                                    </MDPagination>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicLayout>
    );
};

export default Index;
