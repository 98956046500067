import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { MultiSelect } from 'react-multi-select-component';
import axios from 'axios';

const PostNews = () => {
    const options = [
        { label: "Agriculture and natural resources", value: "Agriculture and natural resources" },
        { label: "Capacity development", value: "Capacity development" },
        { label: "Climate change", value: "Climate change" },
        { label: "Economics", value: "Economics" },
        { label: "Education", value: "Education" },
        { label: "Energy", value: "Energy" },
        { label: "Environment", value: "Environment" },
        { label: "Evaluation", value: "Evaluation" },
        { label: "Finance sector development", value: "Finance sector development" },
        { label: "Gender Equality", value: "Gender Equality" },
        { label: "Governance and public sector management", value: "Governance and public sector management" },
        { label: "Health", value: "Health" },
        { label: "Industry and trade", value: "Industry and trade" },
        { label: "Information and Communications Technology", value: "Information and Communications Technology" },
        { label: "Poverty", value: "Poverty" },
        { label: "Private sector development", value: "Private sector development" },
        { label: "Regional cooperation and integration", value: "Regional cooperation and integration" },
        { label: "Social development and protection", value: "Social development and protection" },
        { label: "Transport", value: "Transport" },
        { label: "Urban development", value: "Urban development" },
        { label: "Rural Development", value: "Rural Development" },
        { label: "Water", value: "Water" },
        { label: "Career", value: "Career" },
        { label: "Funding", value: "Funding" },
        { label: "Research", value: "Research" },
        { label: "Institutional Development", value: "Institutional Development" },
        { label: "Infrastructure", value: "Infrastructure" },
        { label: "Humanitarian Aid", value: "Humanitarian Aid" }
    ];

    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [content, setContent] = useState(null);
    const [contentError, setContentError] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [attachmentError, setAttachmentError] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedSector, setSelectedSector] = useState([]);
    const [location, setSelectedLocation] = useState('');
    const [sectorError, setSectorError] = useState(false);


    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);


    const toggleModal = (newsItem = null) => {

        // Create mode
        setTitle('');
        setContent('');
        setSelectedSector([]);
        setAttachment(null);
        setModal(!modal);
    };
    const fetchCountries = async () => {
        try {
            const response = await fetch(`${apiUrl}api/countries`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                setCountries(result)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleAttachmentChange = (e) => {
        const file = e.target.files[0];
        const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
        const maxFileSize = 1024 * 1024; // 1 MB

        if (!allowedExtensions.includes(file.type)) {
            setAttachmentError('Only JPG, JPEG, PNG, and WEBP files are allowed.');
            setAttachment(null);
        } else if (file.size > maxFileSize) {
            setAttachmentError('File size should not be more than 1 MB.');
            setAttachment(null);
        } else {
            setAttachment(file);
            setAttachmentError('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        if (attachment) {
            formData.append('image', attachment);
        }
        formData.append('sectors', selectedSector.map(sector => sector.value));

        try {
            await axios.post(`${apiUrl}api/news/store-news`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('News Published Successfully.');

            toggleModal();
        } catch (error) {
            console.error('Error:', error);
            toast.error('Something went wrong!');
        }
    }
    useEffect(() => {
        fetchCountries();
        if (!modal) {
            // Reset form when modal is closed
            setTitle('');
            setContent('');
            setSelectedSector([]);
            setAttachment(null);
            setTitleError('');
            setContentError('');
            setAttachmentError('');
            setSectorError(false);
        }

    }, [token, modal]);
    return (
        <div className='container news-listing-wrapper mb-5'>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            {user?.user?.profile ?
                                <img
                                    src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.user?.profile}`}
                                    alt="Profile"
                                    className="rounded-circle me-2"
                                    style={{ width: '10%', height: '10%', objectFit: 'cover' }}
                                />
                                :
                                <div
                                    className="d-flex align-items-center justify-content-center rounded-circle me-2"
                                    style={{
                                        width: '65px',
                                        height: '60px',
                                        backgroundColor: '#f7931e',
                                        color: '#fff',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {user?.user?.name?.charAt(0).toUpperCase()}
                                </div>
                            }

                            <input
                                type="text"
                                className="form-control border-0"
                                placeholder="What's in your mind? Start a Post"
                                onClick={toggleModal}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-1">
                            <div
                                className="d-flex align-items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={toggleModal}
                            >
                                <i className="fas fa-newspaper me-2"></i>
                                <span>Write News</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modal && (
                <div
                    className="modal fade show bd-example-modal-lg"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Post News</h5>
                                <button type="button" className="btn-close" onClick={toggleModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="title">
                                            Title <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                            maxLength={200}
                                        />
                                        {titleError && (
                                            <p className="text-danger mt-2"><small>{titleError}</small> </p>
                                        )}
                                    </div>

                                    <div className='form-group col-md-6'>
                                        <label htmlFor="sector">Topic <span className='text-danger'>*</span></label>
                                        {/* <select className="form-control mt-2" id="type" name="type" value={selectedSector} onChange={(e) => setSelectedSector(e.target.value)} required>
                                            <option value="">Select Topic</option>
                                            {options.map(sector => (
                                                <option value={sector.value}>{sector.value}</option>
                                            ))}
                                        </select> */}
                                        <MultiSelect
                                            options={options}
                                            value={selectedSector}
                                            name="sectors"
                                            onChange={setSelectedSector}
                                            labelledBy="Select"
                                            className={`mt-2 `}
                                            required
                                        />
                                        {sectorError && <p className="text-danger mt-2"><small>Please select at least one sector.</small> </p>}

                                    </div>

                                    <div className="form-group col-md-12 mt-4">
                                        <label htmlFor="floatingTextarea2">
                                            Content <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-floating h-100 mt-2">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={content}
                                                required
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data);
                                                }}
                                            />
                                        </div>
                                        {contentError && (
                                            <p className="text-danger mt-2"><small>{contentError}</small> </p>
                                        )}
                                    </div>

                                    <div className="form-group col-md-6  mt-4">
                                        <label htmlFor="type">Country </label>
                                        <select className="form-control mt-2" id="type" name="type" value={location} onChange={(e) => setSelectedLocation(e.target.value)} required>
                                            <option value="">Select Country</option>
                                            {countries.map(country => (
                                                <option key={country.id} value={country.name}>{country.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 mt-4">
                                        <label htmlFor="attachment">Attachment</label>
                                        <div className="input-group mt-2">
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="attachment"
                                                accept="image/jpeg, image/jpg, image/png, image/webp"
                                                onChange={handleAttachmentChange}
                                            />

                                        </div>
                                        {attachmentError && (
                                            <p className="text-danger mt-2"><small>{attachmentError}</small> </p>
                                        )}

                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                    Submit
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PostNews