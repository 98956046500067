import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useState, useEffect } from 'react'
import { selectAuth } from '../../store/slices/authSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@mui/material';
import Category from './Category';
import Jobs from './Jobs';
import Projects from './Projects';
import News from './News';
import CustomLoader from 'components/CustomLoader';
import PostNews from './PostNews';
import Company from './Company';
import Professionals from './Professionals';

const Index = () => {
  const { token, user } = useSelector(selectAuth);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const node = process.env.REACT_APP_NODE_ENV || 'local';
  const redirectTo = user?.user?.role_id === 4 ? '/profile' : '/my-profile';

  const menuItems = [
    { path: '/my-dashboard', icon: 'home', label: 'Feed' },
    { path: user?.user?.role_id === 3 ? '/posted-jobs' : '/applied-jobs', icon: 'work', label: user?.user?.role_id === 3 ? 'Posted Jobs' : 'Applied Jobs' },
    { path: user?.user?.role_id === 3 ? '/my-projects' : '', icon: 'topic', label: user?.user?.role_id === 3 ? 'Posted Projects' : '' },
    { path: '/news-feed', icon: 'newspaper', label: 'Latest News' },
    { path: user?.user?.role_id === 3 ? '/professionals' : '/organizations', icon: 'corporate_fare', label: user?.user?.role_id === 3 ? 'Professionals' : 'Top Organizations' },
    { path: '/all-jobs', icon: 'business_center', label: 'All Jobs' },
    { path: '/all-projects', icon: 'topic', label: 'All Projects' },
    { path: '#', icon: 'notifications', label: 'Notifications', new: true }
  ];

  const featuredjobs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2, slidesToScroll: 2 }
      }
    ]
  };

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}api/get-all-project-job-details`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setProjects(result?.projects || []);
      setJobs(result?.jobs || []);
      setNews(result?.news || []);
      setCompanies(result?.companies || []);
      setProfessionals(result?.professionals || []);
      console.log(result?.companies);
    } catch (error) {
      console.error('Error fetching job data:', error);
      // Optionally, you can set an error state here to display an error message to the user
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BasicLayout>
      {loading && (
        <div className="spinner-overlay">
          <CustomLoader />
        </div>
      )}
      <div className='inner-banner'>
        <div className='container'><h2>Dashboard</h2></div>
      </div>
      <div className="container profile-page mt-5">
        <div className='row'>
          <div className='col-md-3 mb-md-5'>
            <div className="profile-card">
              <div className="profile-header" style={{ backgroundSize: "cover", backgroundPosition: "center", height: "120px" }}></div>
              <div className="profile-image-holder" style={{
                backgroundColor: user?.user?.profile ? "#fff" : "#f8f9fa",
                color: user?.user?.profile ? "#fff" : "#000",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "3px solid #fff",
                margin: "-40px auto 10px",
                position: "relative"
              }}>
                {user?.user?.profile ? (
                  <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.user?.profile}`} alt="Profile picture" style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover"
                  }} />
                ) : (
                  <span className="text-uppercase position-absolute top-50 start-50 translate-middle" style={{
                    fontSize: "30px",
                    lineHeight: "1",
                    fontWeight: "bold"
                  }}>
                    <strong>{user?.user?.name?.charAt(0)}</strong>
                  </span>
                )}
              </div>
              <hr />
              <div className="menu-list mb-3">
                {menuItems.map((item)  => (
                  item.path ? (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`menu-item d-flex align-items-center text-decoration-none ${item.path === window.location.pathname ? 'active' : ''}`}
                    >
                      <Icon className="me-2">{item.icon}</Icon>
                      {item.label}
                      {item.new && (
                        <span
                          className="badge bg-danger"
                          style={{
                            fontSize: '0.75rem', // Smaller font size
                            padding: '0.2em 0.4em', // Adjust padding for a smaller size
                            marginLeft: '0.5rem', // Margin from the left
                          }}
                        >
                          Coming Soon
                        </span>
                      )}
                    </Link>
                  ) : null
                ))}
              </div>

              <Link to={redirectTo} className="view-profile">View Profile</Link>
            </div>

          </div>
          <div className='col-md-6'>
            <PostNews />
            {user?.user?.role_id === 3 && (
              <Professionals professionals={professionals} />
            )}
            <Jobs jobs={jobs} featuredjobs={featuredjobs} token={token} />
            <Projects projects={projects} featuredjobs={featuredjobs} />
            {user?.user?.role_id === 4 && companies.length > 0 && (
              <Company companies={companies} />

            )}
          </div>
          {news.length > 0 && (
            <div className='col-md-3'>
              <News news={news} />
            </div>
          )}

        </div>
      </div>
    </BasicLayout>
  );
};

export default Index;