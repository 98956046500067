import React from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
const Professionals = ({ professionals }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';

    const featuredjobs = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },

        ]
    };
    return (
        <div className="container job-listing-wrapper mt-5">
            <h4 className="text-start mb-3 mt-5">Top Professionals</h4>
            <Slider {...featuredjobs}>
                {professionals?.map((professional, index) => (
                    <div className="job-card" key={index}>
                        {/* Company Logo */}
                        <div className="logo-container text-center">
                            {professional?.details?.profile_picture ? (
                                <img
                                    src={`${apiUrl}${node === "production" ? "public/" : ""}storage/${professional?.details?.profile_picture}`}
                                    alt="Company Logo"
                                    className="company-logo-img"
                                />
                            ) : (
                                <div className="company-logo-img">
                                    {professional?.name?.charAt(0).toUpperCase()}
                                </div>
                            )}
                        </div>

                        {/* Job Information */}
                        <div className="job-details mt-2">
                            <Link to={`/profile/${professional.slug}`}>
                                <h6 className="text-primary">
                                    {professional.name}
                                </h6>
                            </Link>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="mb-1 company text-truncate">{professional.details?.designation}</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-1 company text-truncate">{professional.details?.years_of_experience ?? 0} years Experience</p>
                                </div>
                            </div>
                            <p className="mb-1 company text-truncate">{professional.details?.citizenship}</p>

                        </div>
                    </div>
                ))}
            </Slider>
            <div className="col-md-12 d-flex justify-content-center mt-5 mb-5">
                <Link to={"/professionals"}>
                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill mx-2">
                        Explore More Professionals <i className="material-icons ml-1">arrow_forward</i>
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Professionals
