import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import countryData from '../../assets/data/country-state.json';
import { Autocomplete, TextField } from '@mui/material';
const currencyCodes = [
    "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];
const options = [
    { label: "Agriculture", value: "Agriculture" },
    { label: "Finance", value: "Finance" },
    { label: "Education", value: "Education" },
    { label: "Energy & Extractives", value: "Energy & Extractives" },
    { label: "Water/Sanit/Waste", value: "Water/Sanit/Waste" },
    { label: "Transportation", value: "Transportation" },
    { label: "Info & Communication", value: "Info & Communication" },
    { label: "Industry & Trade/Service", value: "Industry & Trade/Service" },
    { label: "Health", value: "Health" }
];
const allLocations = [
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "PAN India", value: "PAN India" },
    { label: "Nepal", value: "Nepal" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Any Internationally", value: "Any Internationally" },
];
const ShowApplyModal = ({ show, type, handleClose, user, token, companyName, projectId, positions, handleDataFetch }) => {
    const [formData, setFormData] = useState({
        phone: '',
        designation: '',
        years_of_experience: '',
        current_salary: '',
        expected_salary: '',
        notice_period: '',
        availability: '',
        resume_attachment: null,
        position: '',
        currency: 'INR'

    });
    const [data, setData] = useState([]);
    const [selectedSector, setSelectedSector] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [sectorError, setSectorError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [resumeChecked, setResumeChecked] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [resume, setResume] = useState('');
    const [stateCountryList, setStateCountryList] = useState([]);
    const [jobId, setJobId] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('INR');


    const [errors, setErrors] = useState({
        phone: false,
        position: false,
        years_of_experience: false,
        notice_period: false,
        resume_attachment: false,
        selectedSector: false,
        selectedLocations: false,
    });

    const handleSelectCurrency = (code) => {
        setSelectedCurrency(code);
        setFormData({ ...formData, currency: code });
    };

    const handleChange = (e) => {
        const { name, type } = e.target;
        if (type === 'file') {
            setFormData({ ...formData, [name]: e.target.files[0] });
        } else {
            const { value } = e.target;
            if(name == 'position'){
                setJobId(value)
            }
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleKeyPress = (e) => {
        // Check if the pressed key is a digit (0-9)
        if (/\d/.test(e.key)) {
            e.preventDefault(); // Prevent the default behavior (input of digit)
        }
    };
    const closeModal = () => {
        setCurrentPage(1)
        handleClose();
    }

    const handleNextPage = () => {
        // Check all required fields
        console.log('position', formData?.position)
        if(type === 'job'){
            formData.position = positions;
            setJobId(projectId)
        }
        const newErrors = {
            phone: !formData.phone,
            position: !formData.position,
            years_of_experience: !formData.years_of_experience,
            notice_period: !formData.notice_period,
            resume_attachment: currentPage === 2 && formData.resume_attachment == '',
            selectedSector: currentPage === 3 && selectedSector.length === 0,
            selectedLocations: currentPage === 3 && selectedLocations.length === 0,
        };

        // Set errors
        setErrors(newErrors);

        // Check if there are any errors
        if (Object.values(newErrors).some((error) => error)) {
            return; // Do not proceed to the next page if there are errors
        }

        // If all required fields are filled, proceed to the next page
        setCurrentPage(currentPage + 1);
    };


    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const downloadPDF = () => {
        // Retrieve the URL of the PDF file from formData.resume_attachment
        const pdfUrl = `${apiUrl}${node === 'production' ? 'public/' : ''}storage/` + formData?.resume_attachment;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'resume.pdf'; // Set the filename for download

        // Append the anchor element to the body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedSector.length === 0) {
            setSectorError(true);
            return;
        }
        if (selectedLocations.length === 0) {
            setLocationError(true);
            return;
        }
        setSectorError(false);
        setLocationError(false);
        console.log(formData.resume_attachment)
        const updatedData = {
            ...formData,
            sectors: selectedSector.map(sector => sector.value),
            preferred_location: selectedLocations,
        };
        // if(type == 'job'){
        //     setJobId(projectId)
        // }


        try {
            const id = user?.user?.id;
            
            if (id) {
                const response = await axios.post(`${apiUrl}api/applications/submit-application/${id}/${jobId}`, updatedData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                });
                console.log(response.data);
                // handleClose();
                setJobId('')
                handleDataFetch();
                setCurrentPage(currentPage + 1);

            }
        } catch (error) {
            console.error('Error updating user info:', error);
        }
    };


    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/my-profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {

                setFormData({
                    phone: result?.user?.phone || '',
                    designation: result?.details?.designation || '',
                    years_of_experience: result?.details?.years_of_experience || '',
                    current_salary: result?.details?.current_salary || '',
                    expected_salary: result?.details?.expected_salary || '',
                    currency: result?.details?.currency || '',
                    resume_attachment: result?.details?.resume_attachment || '',
                    availability: result?.details?.availability || '',
                    notice_period: result?.details?.notice_period || '',
                });
            }
            if (result?.details?.sectors) {
                const preSelectedSectors = options.filter(option =>
                    result.details.sectors.includes(option.value)
                );
                setSelectedSector(preSelectedSectors);
            }
            if (result?.details?.preferred_location) {

                setSelectedLocations(result?.details?.preferred_location);
            }
            if (result?.details?.resume_attachment) {
                setResume(result?.details?.resume_attachment)
            }


        } catch (error) {

            console.error('Error fetching data:', error);
        }
    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }
    useEffect(() => {
        if(token){
            fetchData();
            fetchCountryStateList();
        }
        

    }, [token]);

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Apply to {companyName}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true" className='p-2'>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100">
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            {currentPage === 1 && (
                                <>
                                    <div className='card border-0'>
                                        <h5 className="card-header">Your Info</h5>
                                        <div className="card-body">
                                            <div className="form-group mt-4">
                                                <label htmlFor="phone">Phone Number <span className='text-danger'>*</span></label>
                                                <input type="tel" className="form-control mt-2" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                                                {errors.phone && <div className="text-danger mt-1 text-sm"><small>This field is required.</small></div>}
                                            </div>
                                            {type == 'project' && (
                                                <div className="form-group mt-4">
                                                <label htmlFor="position">Select Position <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="position" name="position" value={formData?.position}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Position</option>
                                                    {positions && positions.map(position => (
                                                        <option key={position?.id} value={position?.id}>{position?.title}</option>
                                                    ))}
                                                </select>
                                                {errors.position && <div className="text-danger mt-1 text-sm">This field is required.</div>}
                                            </div>
                                            )}
                                            
                                            <div className="form-group mt-4">
                                                <label htmlFor="total_exprience">Total Experience (in years) <span className='text-danger'>*</span></label>
                                                <input
                                                    type="number"
                                                    className="form-control mt-2"
                                                    id="years_of_experience"
                                                    name="years_of_experience"
                                                    value={formData.years_of_experience}
                                                    required
                                                    onChange={handleChange}
                                                    pattern="[0-9]+(\.[0-9]+)?"
                                                    min={0}
                                                    max={70}
                                                />
                                                {errors.years_of_experience && <div className="text-danger mt-1 text-sm">This field is required.</div>}

                                            </div>
                                            <div className="form-group mt-4">
                                                <label htmlFor="notice_period">Notice Period (in days) <span className='text-danger'>*</span></label>
                                                <input type="number" className="form-control mt-2" id="notice_period" name="notice_period" value={formData.notice_period} onChange={handleChange} pattern="[0-9]+(\.[0-9]+)?"
                                                    min={0} required />
                                                {errors.notice_period && <div className="text-danger mt-1 text-sm">This field is required.</div>}

                                            </div>
                                            <div className="form-group mt-4">
                                                <label htmlFor="availability">Availability </label>
                                                <select className="form-control mt-2" id="availability" name="availability" value={formData.availability} onChange={handleChange}>
                                                <option value="">Select Availability</option>
                                                <option value="Immediately">Immediately</option>
                                                <option value="Proposal">Proposal</option>
                                                <option value="Any Opportunity">Any Opportunity</option>
                                                </select>
                                                
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" onClick={handleNextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}

                            {currentPage === 2 && (
                                <>
                                    <div className='card border-0'>
                                        <h5 className="card-header">CV</h5>
                                        <div className="card-body">
                                            {resume ?
                                                <>
                                                    <div className="input-group mt-4 mb-3">
                                                        <button className="btn btn-danger" type="button" id="button-addon1" onClick={() => setResumeChecked(!resumeChecked)}>PDF</button>
                                                        <input type="text" className="form-control" value={resume.substring(resume.indexOf('/') + 1)}
                                                            aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                        <span className="input-group-text" onClick={downloadPDF}><i className="material-icons" style={{ cursor: 'pointer', fontSize: '1.5rem' }} title="Download">download</i></span>

                                                        <div className="input-group-text">
                                                            <input
                                                                className="form-check-input mt-0"
                                                                type="checkbox"
                                                                aria-label="Radio button for following text input"
                                                                checked={resumeChecked}
                                                                onChange={() => setResumeChecked(!resumeChecked)}
                                                            />

                                                        </div>
                                                    </div>
                                                    {!resumeChecked && (
                                                        <div className="form-group mt-4">
                                                            <label htmlFor="resume_attachment">Upload CV </label>
                                                            <input type="file" className="form-control mt-2" id="resume_attachment" accept=".pdf,.doc,.docx" onChange={handleChange} name="resume_attachment" />
                                                        </div>
                                                    )}

                                                </>

                                                :
                                                <div className="form-group mt-4 mb-3">
                                                    <label htmlFor="resume_attachment">Upload CV <span className='text-danger'>*</span></label>
                                                    <input type="file" className="form-control" id="resume_attachment" accept=".pdf,.doc,.docx" onChange={handleChange} name="resume_attachment" required />
                                                    {errors.resume_attachment && <div className="text-danger mt-1 text-sm">This field is required.</div>}
                                                </div>


                                            }
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={handlePreviousPage}>Previous</button>
                                                <button type="button" className="btn btn-primary" onClick={handleNextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentPage === 3 && (
                                <>
                                    <div className='card border-0'>
                                        <h5 className="card-header">Sectors & Preferred Location</h5>
                                        <div className="card-body">
                                            <div className='form-group mt-4 mb-4'>
                                                <label for="sector">Sectors <span className='text-danger'>*</span></label>
                                                <MultiSelect
                                                    options={options}
                                                    value={selectedSector}
                                                    name="sectors"
                                                    onChange={setSelectedSector}
                                                    labelledBy="Select"
                                                    className={`mt-2 ${sectorError ? 'is-invalid' : ''}`}
                                                    required
                                                />

                                                {errors.selectedSector && <div className="text-danger mt-1 text-sm">Please select at least one sector.</div>}
                                            </div>
                                            <div className='form-group mt-4 mb-4'>
                                                <label for="sector">Preferred Location <span className='text-danger'>*</span></label>
                                                
                                                <Autocomplete
                                                    multiple // Allows multiple selections
                                                    options={stateCountryList}
                                                    freeSolo
                                                    className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                                                    value={selectedLocations}
                                                    required
                                                    onChange={(event, newValue) => setSelectedLocations(newValue)}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />

                                                {errors.selectedLocations && <div className="text-danger mt-1 text-sm">Please select at least one location.</div>}

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={handlePreviousPage}>Previous</button>
                                                <button type="button" className="btn btn-primary" onClick={handleNextPage}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentPage === 4 && (
                                <>
                                    <div className='card border-0'>
                                        <h5 className="card-header">Salary Details</h5>
                                        <div className="card-body">
                                            <div className="form-group mt-4">
                                                <label htmlFor="current_salary">Current Salary </label>
                                                <div class="input-group mt-2">
                                                    <input type="text" className="form-control" id="current_salary" name="current_salary" value={formData?.current_salary} onChange={handleChange} />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedCurrency || 'Select Currency'}
                                                        </button>
                                                        <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                            {currencyCodes.map((code, index) => (
                                                                <a
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    onClick={() => handleSelectCurrency(code)}
                                                                >
                                                                    {code}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group mt-4">
                                                <label htmlFor="expected_salary">Expected Salary </label>
                                                <div class="input-group mt-2">
                                                    <input type="text" className="form-control" id="expected_salary" name="expected_salary" value={formData?.expected_salary} onChange={handleChange} />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedCurrency || 'Select Currency'}
                                                        </button>
                                                        <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                            {currencyCodes.map((code, index) => (
                                                                <a
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    onClick={() => handleSelectCurrency(code)}
                                                                >
                                                                    {code}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={handlePreviousPage}>Previous</button>
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentPage === 5 && (
                                <>
                                    <div className='card border-0'>
                                        <div className="card-body">
                                            <div className="thankyou-wrapper">
                                                <div className="row d-flex vh-60 justify-content-center align-items-center">
                                                    <div className="col-md-10">
                                                        <div className="icon_wrapp">
                                                            <span className="material-icons">check_circle</span>
                                                        </div>
                                                        <div className="content">
                                                            <h2>
                                                                Your application has been successfully submitted.
                                                            </h2>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-3">
                                                <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ShowApplyModal;
