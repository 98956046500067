import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import CustomLoader from 'components/CustomLoader'


const organizationTypes = [
    { label: "Construction", value: "Construction" },
    { label: "Development Consultant", value: "Development Consultant" },
    { label: "Funding Agency", value: "Funding Agency" },
    { label: "Finance", value: "Finance" },
    { label: "Foundation", value: "Foundation" },
    { label: "Government Organization", value: "Government Organization" },
    { label: "Institute", value: "Institute" },
    { label: "Manufacturer", value: "Manufacturer" },
    { label: "Service Provider", value: "Service Provider" },
    { label: "Goods Supplier", value: "Goods Supplier" },
    { label: "Works", value: "Works" },
    { label: "Other", value: "Other" }

];
const Index = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const { token, user } = useSelector(selectAuth);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [searchLocation, setSearchLocation] = useState('');
    const [isReadMore, setIsReadMore] = useState(false);



    const handleTypeChange = (event) => {
        const value = event.target.value;
        setSelectedType(prev => [...prev, value]);
    };


    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchFilteredData = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            if (searchLocation) {
                queryParams.append('location', searchLocation);
            }
            if (selectedType) {
                queryParams.append('organization_type', selectedType);
            }
            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/organizations?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result);
                setData(result?.data?.data || []);
                setUserData(user);
                setCurrentPage(result.data.current_page);
                setTotalPages(result.data.last_page);
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    // const fetchCountries = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}api/countries`, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         const result = await response.json();
    //         if (Array.isArray(result)) {
    //             setOptions(result.map(country => ({ label: country.name, value: country.name })));
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const handleSearchLocationChange = (event) => {
        setSearchLocation(event.target.value);
    };

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    useEffect(() => {
        fetchFilteredData();
        // fetchCountries();
    }, [token, currentPage]);

    const parseOperationalCountries = (countries) => {
        if (Array.isArray(countries)) {
            return countries;
        }
        try {
            return JSON.parse(countries);
        } catch (e) {
            return [];
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    return (
        <BasicLayout>
            {/* {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )} */}
            <div className='inner-banner'>
                <div className='container'>
                    <h2>Organizations</h2>
                </div>
            </div>
            <div className="container org-listing-wrapper mt-2">
                <div className='row'>
                    <div className='col-md-3 sidebar position-sticky mb-3 shadow-md border-2 rounded-3 mt-3' >
                        {/* Show Filter Button for Mobile Only */}
                        <button
                            className="btn btn-warning d-md-none mb-3"
                            onClick={toggleFilters}
                        >
                            {showFilters ? 'Hide Filters' : 'Show Filters'}
                        </button>
                        <div className={`organization-filters ${showFilters ? '' : 'd-none d-md-block'}`}>
                            <div className='row'>
                                {/* Search by Sector Section */}
                                <div className='form-group col-md-12'>
                                    <div className="accordion" id="sectorAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSector">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSector" aria-expanded="true" aria-controls="collapseSector">
                                                    Organization Type
                                                </button>
                                            </h2>
                                            <div id="collapseSector" className="accordion-collapse collapse show" aria-labelledby="headingSector" data-bs-parent="#sectorAccordion">
                                                <div className="accordion-body">
                                                    {/* Default Sector Checkboxes */}
                                                    {organizationTypes.map((type, index) => (
                                                        <div key={index}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={type.value}
                                                                    checked={selectedType.includes(type.value)}
                                                                    onChange={handleTypeChange}
                                                                    className='me-2'
                                                                />
                                                                {type.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 d-flex gap-1 mt-4 mt-md-0 mb-3'>
                                    <button className="btn btn-warning w-50 rounded-3" type="button" onClick={fetchFilteredData}>
                                        Apply
                                    </button>
                                    <button
                                        className="btn btn-secondary w-50 rounded-3"
                                        onClick={() => {
                                            fetchFilteredData();
                                            setSelectedLocations([]);
                                            setSelectedType('');
                                            setSearchTerm('');
                                        }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className='project-filters'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-group location-fild col-md-12'>
                                        <label>Operational Countries</label>
                                        <div className="form-group position-relative">
                                            <Autocomplete
                                                multiple
                                                options={options}
                                                freeSolo
                                                value={selectedLocations}
                                                onChange={(event, newValue) => {
                                                    setSelectedLocations(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="" />}
                                            />
                                            <button className="btn btn-primary loc-serch" type="button" onClick={fetchFilteredData}>
                                                <span class="material-symbols-outlined"> search </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        <label>Organization Type</label>
                                        <select
                                            className="form-control mt-2"
                                            id="availability"
                                            name="availability"
                                            value={selectedType}
                                            onChange={(event) => {
                                                handleTypeChange(event);
                                            }}
                                        >
                                            <option value="">Select Organization Type</option>
                                            {organizationTypes?.map((type, index) => (
                                                <option key={index} value={type.value}>{type.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group project-wise-search col-md-12 mt-2'>
                                        <label>Search by name</label>
                                        <div className="form-group position-relative">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by name"
                                                aria-describedby="button-addon2"
                                                value={searchTerm}
                                                onChange={handleSearchTermChange}
                                            />
                                            <button className="btn btn-primary location-fild" type="button" onClick={fetchFilteredData}>
                                                <span class="material-symbols-outlined"> search </span>
                                            </button>
                                        </div>

                                    </div>
                                    <div className='form-group mt-2 float-end'>
                                        <button
                                            className="btn btn-warning mt-2"
                                            onClick={() => {
                                                setSelectedLocations([]);
                                                setSelectedType('');
                                                setSearchTerm('');
                                                fetchFilteredData();
                                            }}
                                        >
                                            Clear Filters
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className='col-md-9'>
                        <div className='banner text-center'>
                            <div className="d-flex w-100" style={{ padding: "20px" }}>
                                <div className="d-flex align-items-center shadow-sm border border-2" style={{ padding: "5px 10px" }}>
                                    {/* Job Title Input */}
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <i className="bi bi-search text-muted me-2"></i>
                                        <input
                                            type="text"
                                            className="form-control border-0 shadow-none"
                                            placeholder={'Search by Name'}
                                            style={{ outline: "none" }}
                                            value={searchTerm}
                                            onChange={handleSearchTermChange}
                                        />
                                    </div>

                                    <div className="vr mx-2"></div>

                                    <div className="d-flex align-items-center flex-grow-1">
                                        <i className="bi bi-geo-alt text-muted me-2"></i>
                                        <input
                                            type="text"
                                            className="form-control border-0 shadow-none"
                                            placeholder="Location"
                                            style={{ outline: "none" }}
                                            value={searchLocation}
                                            onChange={handleSearchLocationChange}
                                        />
                                    </div>

                                    <button
                                        className="btn btn-warning rounded-circle d-flex align-items-center justify-content-center custom-search-btn"
                                        style={{ width: "40px", height: "40px" }}
                                        onClick={fetchFilteredData}
                                    >
                                        <i className="bi bi-arrow-right text-white"></i>
                                    </button>

                                </div>
                            </div>
                        </div>
                        {data && data.length > 0 ? (
                            <div className='row mt-4'>
                                {data.map((organization, index) => (
                                    <div className="col-md-12 mb-3" key={index}>
                                        <div className="job-listing-card d-flex flex-column flex-md-row">
                                            {/* Company Logo */}
                                            {organization?.logo ? (
                                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${organization?.logo}`}
                                                    alt="Company Logo"
                                                    className="job-logo img-fluid mt-1"
                                                />
                                            ) : (
                                                <div className="letter-cap me-3">{organization?.company_name?.charAt(0)}</div>
                                            )}

                                            {/* Job Details */}
                                            <div className="job-details flex-grow-1">

                                                <Link to={`/organization/${organization?.slug}`} className="text-decoration-none text-dark">
                                                    <h5 className="job-title text-primary">{organization?.company_name}</h5>
                                                </Link>
                                                <p className="job-location text-muted">{organization?.headquarter}</p>

                                                {/* Job Badges */}
                                                <div className="job-meta flex-wrap">
                                                    <span className="badge job-badge rounded-pill">{organization?.organization_type}</span>

                                                    {Array.isArray(parseOperationalCountries(organization?.operational_countries)) &&
                                                        parseOperationalCountries(organization?.operational_countries).length > 0 && (
                                                            <>
                                                                {parseOperationalCountries(organization?.operational_countries).map((country, idx) => (
                                                                    <span key={idx} className="badge job-badge rounded-pill">{country}</span>
                                                                ))}
                                                            </>
                                                        )}

                                                </div>

                                            </div>

                                        </div>

                                        <hr></hr>
                                    </div>
                                ))}
                            </div>

                        ) : (
                            <h2 className='text-center mt-3'>No Records Found !!</h2>
                        )}
                        <div className='container pagination'>
                            {/* Pagination */}
                            {totalPages > 1 && (
                                <MDPagination size="medium" className="page-list">
                                    {currentPage > 1 && (
                                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                                            <Icon>keyboard_arrow_left</Icon>
                                        </MDPagination>
                                    )}
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <MDPagination item
                                            key={i + 1}
                                            active={currentPage === i + 1}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </MDPagination>
                                    ))}
                                    {currentPage < totalPages && (
                                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                                            <Icon>keyboard_arrow_right</Icon>
                                        </MDPagination>
                                    )}
                                </MDPagination>
                            )}
                        </div>

                    </div>

                </div>
            </div>


        </BasicLayout>
    )
}

export default Index
