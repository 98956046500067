import React from 'react'
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Slider from 'react-slick';

const Projects = ({ projects, featuredjobs }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : '';
        return formattedDeadline;
    }
    return (
        <div className="container job-listing-wrapper mt-5">
            <h4 className="text-start mb-3">Featured Projects</h4>
            <Slider {...featuredjobs}>
                {projects?.map((project, index) => (
                    <div className="job-card" key={index}>
                        {/* Company Logo */}
                        <div className="logo-container text-center">
                            {project?.company?.logo ? (
                                <img
                                    src={`${apiUrl}${node === "production" ? "public/" : ""}storage/${project.company.logo}`}
                                    alt="Company Logo"
                                    className="company-logo-img"
                                />
                            ) : (
                                <div className="company-logo">
                                    {project?.company?.company_name?.charAt(0).toUpperCase()}
                                </div>
                            )}
                        </div>

                        {/* Job Information */}
                        <div className="job-details mt-2">
                            <Link to={`/project/${project.slug}`}>
                                <h6 className="text-primary">
                                    {project.name}
                                </h6>
                            </Link>
                            <p className="mb-1 company text-truncate">{project.location}</p>
                            <p className="mb-1 company text-truncate">{project.company.company_name}</p>
                            <p className="mb-1">
                                <span className="badge bg-secondary text-white">{project.sector}</span>
                                <span className="ms-1 badge bg-secondary text-white">{project.stage}</span>
                                <span className="ms-1 badge bg-secondary text-white">{project.budget} {project.currency}</span>
                            </p>
                            <p className="text-muted">Posted on {convertDate(project.created_at)}</p>

                            {/* Save Job Button and Job Stage */}
                            {/* <div className="d-flex justify-content-between align-items-center mt-2">
                                <button className="btn-save">
                                    <i className="bi bi-bookmark"></i> Save job
                                </button>
                                
                            </div> */}
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="col-md-12 d-flex justify-content-center mt-5 mb-2">
                <Link to={"/all-projects"}>
                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill mx-2">
                        Explore More Projects <i className="material-icons ml-1">arrow_forward</i>
                    </button>
                </Link>
            </div>
        </div>


    )
}

export default Projects
