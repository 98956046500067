import React, { useEffect, useState } from 'react';

import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../store/slices/authSlice';
import countryData from '../../../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import CustomLoader from 'components/CustomLoader';

const ViewExperts = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const { token, user } = useSelector(selectAuth);
    const location = useLocation();

    const { slug } = useParams(); // Get the role slug from the URL params

    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const navigate = useNavigate();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedAvailability, setSelectedAvailability] = useState('');
    const [selectedExperience, setSelectedExperience] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    // Fetch role details based on ID
    // const fetchApplicants = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}api/project/${slug}/experts`, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         const result = await response.json();
    //         if (response.ok) {
    //             setData(result);
    //             setFilteredData(result);
    //             console.log(filteredData)

    //         } else {
    //             setError('Failed to fetch applicants details.');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching role details:', error);
    //         setError('Failed to fetch applicants details. Please try again later.');
    //     }
    // };


    const handleAvailabilityChange = (event) => {
        const value = event.target.value;
        setSelectedAvailability(value);
    };

    const handleExperienceChange = (event) => {
        const value = event.target.value;
        setSelectedExperience(value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };

    const fetchFilteredData = async () => {
        try {
            setLoading(true);
            const type = location.pathname.split('/')[1];
            // Build query parameters for filtering
            const queryParams = new URLSearchParams();
            if (selectedLocations.length > 0) {
                queryParams.append('locations', selectedLocations.join(','));
            }

            if (selectedAvailability) {
                queryParams.append('availability', selectedAvailability);
            }

            if (selectedExperience) {
                queryParams.append('experience', selectedExperience);
            }

            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);
            
            if(type == 'project' || type == 'job'){
                const response = await fetch(`${apiUrl}api/project/${slug}/${type}/experts?${queryParams.toString()}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();
                if (result) {
                    setLoading(false);
                    setData(result?.data?.data || []);
                    setCurrentPage(result.data.current_page); // Set current page
                    setTotalPages(result.data.last_page); // Set total pages
                }
            }
            

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };


    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }



    useEffect(() => {
        fetchFilteredData();
        fetchCountryStateList();
    }, [token, slug, selectedAvailability, selectedExperience, currentPage]);
    return (
        <BasicLayout>
            {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )}
            <div className='inner-banner'>
                <div className='container d-flex justify-content-between align-items-center'>
                    <h2>Matched Professionals</h2>
                    <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
            <div className="container professional-listing-wrapper mt-2">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='project-filters'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='form-group location-fild col-md-12'>
                                            <div className="form-group position-relative">
                                                <label>Search by Location</label>
                                                <Autocomplete
                                                    multiple
                                                    options={stateCountryList}
                                                    freeSolo
                                                    value={selectedLocations}
                                                    onChange={(event, newValue) => {
                                                        setSelectedLocations(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />
                                                <button className="btn btn-primary loc-serch mt-1" type="button" onClick={fetchFilteredData}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Select Experience</label>
                                            <select
                                                className="form-control mt-2"
                                                id="Experience"
                                                name="Experience"
                                                value={selectedExperience}
                                                onChange={(event) => {
                                                    handleExperienceChange(event);
                                                }}
                                            >
                                                <option value="">Select Experience</option>
                                                <option key='0-2yr' value='0-2'>0-2 yr</option>
                                                <option key='2-5yr' value='2-5'>2-5 yr</option>
                                                <option key='5-10yr' value='5-10'>5-10 yr</option>
                                                <option key='10yr+' value='10'>10 yr+</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Select Availability</label>
                                            <select
                                                className="form-control mt-2"
                                                id="availability"
                                                name="availability"
                                                value={selectedAvailability}
                                                onChange={(event) => {
                                                    handleAvailabilityChange(event);
                                                }}
                                            >
                                                <option value="">Select Availability</option>
                                                <option value="Immediately">Immediately</option>
                                                <option value="Proposal">Proposal</option>
                                                <option value="Any Opportunity">Any Opportunity</option>
                                            </select>
                                        </div>
                                        <div className='form-group location-fild col-md-12'>
                                            <div className="form-group position-relative">
                                                <label>Search by Name/Designation</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name/Designation"
                                                    aria-describedby="button-addon2"
                                                    value={searchTerm}
                                                    onChange={handleSearchTermChange}
                                                />
                                                <button className="btn btn-primary" type="button" onClick={fetchFilteredData}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-12 mt-2 float-end'>
                                            <button
                                                className="btn btn-warning mt-2"
                                                onClick={() => {
                                                    setSelectedLocations([]);
                                                    setSelectedAvailability('');
                                                    setSearchTerm('');
                                                    setSelectedExperience('');
                                                    fetchFilteredData();// Trigger fetch on clearing filters
                                                }}
                                            >
                                                Clear Filters
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        {data?.length > 0
                            ?
                            <>
                                {data?.map((user, index) => (
                                    <>
                                        <div className="col-md-12 py-3" key={index}>
                                            <div className="card project-list shadow">
                                                <div className="card-body">
                                                    <div className="row align-items-center company-title">
                                                        <div className="col-md-2">
                                                            <div className="profile-wrapper d-inline-flex align-items-center justify-content-center" style={{ backgroundColor: user?.profile_picture ? '#fff' : '#fff', color: user?.profile_picture ? '#fff' : '#000' }}>

                                                                {user?.profile_picture ? (
                                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.profile_picture}`} alt="Profile picture" />
                                                                ) : (
                                                                    <p className="letter-cap"><strong>{user?.user?.name?.charAt(0)}</strong></p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10 applicants_profile">
                                                            <Link to={`/profile/${user?.user?.slug}`} target='_blank'>
                                                                <h2>{user?.user?.name}</h2>
                                                            </Link>
                                                            <p>{user?.designation}</p>
                                                            {/* <p className='text-lowercase'><a href={`mailto:${user?.user?.email}`} ><span class="material-symbols-outlined">mail</span> {user?.user?.email}</a></p>
                                                            
                                                            <p className='text-primary'><span class="material-symbols-outlined">call</span> {user?.user?.phone}</p> */}
                                                            {user?.resume_attachment && (
                                                                <Link to={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.resume_attachment}`} target='_blank'>
                                                                    <p className='text-end text-success'>
                                                                   <span class="material-symbols-outlined">draft</span> CV available
                                                                </p>
                                                                </Link>
                                                                
                                                            )}
                                                        </div>
                                                        {/* {user?.position && (
                                                                <div className='text-end'>
                                                                    <p><strong>Applied for </strong><span className='text-success'>{user?.position}</span></p>

                                                                </div>
                                                            )} */}
                                                            
                                                    </div>
                                                    <hr className='mb-1'></hr>
                                                    <div className='meta-info'>
                                                        {user?.experience && (
                                                            <>
                                                                <div className='work-exprience'>

                                                                    <label>Work Experience</label>
                                                                    <ul className='list-unstyled'>
                                                                        {user?.experience.map((exp, index) => (
                                                                            <li key={index}>
                                                                                <span className="material-icons">chevron_right</span>{exp.title} - {exp?.company_name}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <hr></hr>
                                                            </>


                                                        )}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div>
                                                                    <label>Experience</label>
                                                                    <h6>{user?.years_of_experience ?? 0} years</h6>
                                                                </div>
                                                            </div>
                                                            {user?.citizenship && (
                                                                <div className="col">
                                                                    <div>
                                                                        <label>Citizenship</label>
                                                                        <h6>{user?.citizenship}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {user?.current_location && (
                                                                <div className="col">
                                                                    <div>
                                                                        <label>Currently in</label>
                                                                        <h6>{user?.current_location}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>

                                ))}
                            </>
                            :
                            <>
                                <div className="col-md-12 mt-5">
                                    <div className="card-shadow">
                                        <h2 className='text-center'>No Records Found !!</h2>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
            {/* <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={9} md={9} lg={8} xl={6}>
                    <div className="container mt-5 p-5">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card bg-secondary text-white p-3'>
                                    <div>
                                        <button className='btn btn-light w-20 float-end' onClick={() => navigate(-1)}>Back</button>
                                    </div>

                                    <div className='card-title text-center'>
                                        <h3>Matched Professionals</h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>

                                            <div className='form-group col-md-12'>
                                                <label>Location</label>
                                                <div className="d-flex">
                                                    <Autocomplete
                                                        multiple // Allows multiple selections
                                                        options={stateCountryList}
                                                        freeSolo
                                                        className="flex-grow-1 bg-white"
                                                        value={selectedLocations}
                                                        onChange={(event, newValue) => {
                                                            setSelectedLocations(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="" />}
                                                    />
                                                    <div className="ms-2"> 
                                                        <button className="btn btn-primary" type="button" onClick={fetchFilteredData}>
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 mt-4">
                                                <select
                                                    className="form-control mt-2"
                                                    id="Experience"
                                                    name="Experience"
                                                    value={selectedExperience}
                                                    onChange={(event) => {
                                                        handleExperienceChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Experience</option>
                                                    <option key='0-2yr' value='0-2'>0-2 yr</option>
                                                    <option key='2-5yr' value='2-5'>2-5 yr</option>
                                                    <option key='5-10yr' value='5-10'>5-10 yr</option>
                                                    <option key='10yr+' value='10'>10 yr+</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mt-4">
                                                <select
                                                    className="form-control mt-2"
                                                    id="availability"
                                                    name="availability"
                                                    value={selectedAvailability}
                                                    onChange={(event) => {
                                                        handleAvailabilityChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Availability</option>
                                                    <option value="Immediately">Immediately</option>
                                                    <option value="Proposal">Proposal</option>
                                                    <option value="Any Opportunity">Any Opportunity</option>
                                                    <option value="Not Available">Not Available</option>
                                                </select>
                                            </div>
                                            <div className='form-group col-md-9 mt-4'>
                                                <div className="input-group mb-3 mt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by name or designation"
                                                        aria-describedby="button-addon2"
                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                    />
                                                    <button className="btn btn-primary" type="button" onClick={fetchFilteredData}>
                                                        Search
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='form-group col-md-3 mt-4 float-end'>
                                                <button
                                                    className="btn btn-warning mt-2"
                                                    onClick={() => {
                                                        setSelectedLocations([]);
                                                        setSelectedAvailability('');
                                                        setSearchTerm('');
                                                        setSelectedExperience('');
                                                        fetchFilteredData(); // Trigger fetch on clearing filters
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {data.length > 0 ?
                                <>
                                    {data?.map((user, index) => (
                                        <>
                                            <div className="col-md-12 py-3">
                                                <div className="card">
                                                    <div className="card-body">

                                                        <div className="row align-items-center">
                                                            <div className="col-md-2">
                                                                <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px', backgroundColor: user?.profile_picture ? '#000' : '#87CEEB', color: user?.profile_picture ? '#87CEEB' : '#000' }}>

                                                                    {user?.profile_picture ? (
                                                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.profile_picture}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    ) : (
                                                                        <span className="text-uppercase"><strong>{user?.user?.name?.charAt(0)}</strong></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <Link to={`/project/${slug}/matched-professionals/${user?.user?.slug}`}>
                                                                    <h5 className="card-title text-primary"><strong>{user?.user?.name}</strong></h5>
                                                                </Link>

                                                                <p className="card-title"><strong>{user?.designation}</strong></p>

                                                            </div>

                                                        </div>
                                                        <hr></hr>
                                                        <div style={{ background: '#fafafa' }}>
                                                            {user?.experience && (
                                                                <>
                                                                    <h5 className="card-title">Work Experience</h5>
                                                                    <ul className='list-unstyled'>
                                                                        {user?.experience.map((exp, index) => (
                                                                            <li key={index}>
                                                                                <span><small>
                                                                                    <i className="material-icons fs-18">chevron_right</i>{exp.title} - {exp?.company_name}</small>
                                                                                </span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>

                                                                </>


                                                            )}
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div>
                                                                        <h6><strong>Experience</strong></h6>
                                                                        <p><small>{user?.years_of_experience ?? 0} years</small></p>
                                                                    </div>
                                                                </div>
                                                                {user?.citizenship && (
                                                                    <div className="col">
                                                                        <div>
                                                                            <h6><strong>Citizenship</strong></h6>
                                                                            <p className='text-wrap'><small>{user?.citizenship}</small></p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user?.current_location && (
                                                                    <div className="col">
                                                                        <div>
                                                                            <h6><strong>Currently in</strong></h6>
                                                                            <p className='text-wrap'><small>{user?.current_location}</small></p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    ))}
                                </>
                                :
                                <>
                               <div className="col-md-12 mt-5">
                                        <div className="card-shadow">
                                            <h2 className='text-center'>No Records Found !!</h2>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                    </div>

                </Grid>
            </Grid> */}
            {/* Pagination */}
            {totalPages > 1 && (
                <MDPagination size="medium">
                    {currentPage > 1 && (
                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, i) => (
                        <MDPagination item
                            key={i + 1}
                            active={currentPage === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </MDPagination>
                    ))}
                    {currentPage < totalPages && (
                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            )}
        </BasicLayout>
    );
};

export default ViewExperts;