import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';
import CustomLoader from 'components/CustomLoader'
const Index = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { token, user } = useSelector(selectAuth);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedAvailability, setSelectedAvailability] = useState('');
    const [selectedExperience, setSelectedExperience] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [infraChecked, setInfraChecked] = useState(false);
    const [isReadMore, setIsReadMore] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [searchLocation, setSearchLocation] = useState('');
    const [isExpanded, setIsExpanded] = useState(false); // Local state for each user

    const experienceLevels = [
        { label: "0-2 yr", value: "0-2" },
        { label: "2-5 yr", value: "2-5" },
        { label: "5-10 yr", value: "5-10" },
        { label: "10 yr+", value: "10" }
    ];

    const availabilityOptions = [
        { label: "Immediately", value: "Immediately" },
        { label: "Proposal", value: "Proposal" },
        { label: "Any Opportunity", value: "Any Opportunity" }
    ];



    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}api/seekers`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         const result = await response.json();
    //         setData(result);
    //         setFilteredData(result);
    //         setUserData(user)
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorName = event.target.value;
        setSelectedSector(sectorName);
    };

    const handleAvailabilityChange = (event) => {
        const value = event.target.value;
        setSelectedAvailability(value);
    };

    const handleExperienceChange = (event) => {
        const value = event.target.value;
        setSelectedExperience(value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };

    const handleSearchLocationChange = (event) => {
        setSearchLocation(event.target.value); // Added handler for search term change
    };

    const fetchFilteredData = async () => {
        try {
            setLoading(true);
            // Build query parameters for filtering
            const queryParams = new URLSearchParams();
            if (searchLocation) {
                queryParams.append('location', searchLocation);
            }

            if (selectedSector) {
                queryParams.append('sectors', selectedSector.join(','));
            }

            if (selectedAvailability) {
                queryParams.append('availability', selectedAvailability.join(','));
            }

            if (selectedExperience) {
                queryParams.append('experience', selectedExperience.join(','));
            }

            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            if (infraChecked) {
                queryParams.append('infraChecked', infraChecked); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/seekers?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result);

                setData(result?.data?.data || []);
                setUserData(user)
                setCurrentPage(result.data.current_page); // Set current page
                setTotalPages(result.data.last_page); // Set total pages
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };


    // const fetchCountryStateList = () => {
    //     if (countryData && countryData.countries) {
    //         const list = countryData.countries.flatMap((country) =>
    //             country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
    //         );
    //         setStateCountryList(list);
    //     }
    // }
    const fetchSectorsData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            console.error('Error fetching data:', error);
        }
    };

    const handleSectorCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedSector((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleExperienceCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedExperience((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    const handleAvailabilityCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedAvailability((prev) =>
            prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
        );
    };

    useEffect(() => {
        // fetchData();
        fetchFilteredData();
        fetchSectorsData();
        // fetchCountryStateList();
    }, [token, currentPage, infraChecked]);
    return (
        <BasicLayout>
            {/* {loading && (
                <div className="spinner-overlay">
                    <CustomLoader />
                </div>
            )} */}

            <div className='inner-banner'>
                <div className='container'>
                    <h2>Professionals</h2>
                </div>
            </div>

            <div className="container professional-listing-wrapper mt-2">
                <div className='row'>
                    <div className='col-md-3 sidebar position-sticky mb-3 shadow-md border-2 rounded-3 mt-3' >
                        {/* Show Filter Button for Mobile Only */}
                        <button
                            className="btn btn-warning d-md-none mb-3"
                            onClick={toggleFilters}
                        >
                            {showFilters ? 'Hide Filters' : 'Show Filters'}
                        </button>
                        <div className={`professional-filters ${showFilters ? '' : 'd-none d-md-block'}`}>
                            <div className='row'>
                                {/* Search by Sector Section */}
                                <div className='form-group col-md-12'>
                                    <div className="accordion" id="sectorAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingSector">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSector" aria-expanded="true" aria-controls="collapseSector">
                                                    Search by Sector
                                                </button>
                                            </h2>
                                            <div id="collapseSector" className="accordion-collapse collapse show" aria-labelledby="headingSector" data-bs-parent="#sectorAccordion">
                                                <div className="accordion-body">
                                                    {/* Default Sector Checkboxes */}
                                                    {sectors.slice(0, 4).map((sector, index) => (
                                                        <div key={index}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={sector.name}
                                                                    checked={selectedSector.includes(sector.name)}
                                                                    onChange={handleSectorCheckboxChange}
                                                                    className='me-2'
                                                                />
                                                                {sector.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                    {/* View More Button for Sectors */}
                                                    <button className="btn btn-link p-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSectors" aria-controls="offcanvasSectors">
                                                        View More
                                                    </button>
                                                    {/* Offcanvas for Sectors */}
                                                    <div className="offcanvas offcanvas-start mt-5 py-5" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSectors" aria-labelledby="offcanvasSectorsLabel">
                                                        <div className="offcanvas-header">
                                                            <h5 className="offcanvas-title" id="offcanvasSectorsLabel">All Sectors</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                        </div>
                                                        <div className="offcanvas-body">
                                                            {sectors.map((sector, index) => (
                                                                <div key={index}>
                                                                    <label>
                                                                        <input
                                                                            type="checkbox"
                                                                            value={sector.name}
                                                                            checked={selectedSector.includes(sector.name)}
                                                                            onChange={handleSectorCheckboxChange}
                                                                            className='me-2'
                                                                        />
                                                                        {sector.name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Search by Experience Section */}
                                <div className='form-group col-md-12'>
                                    <div className="accordion" id="experienceAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingExperience">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExperience" aria-expanded="true" aria-controls="collapseExperience">
                                                    Search by Experience
                                                </button>
                                            </h2>
                                            <div id="collapseExperience" className="accordion-collapse collapse show" aria-labelledby="headingExperience" data-bs-parent="#experienceAccordion">
                                                <div className="accordion-body">
                                                    {/* Default Experience Checkboxes */}
                                                    {experienceLevels.map((exp, index) => (
                                                        <div key={index}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={exp.value}
                                                                    checked={selectedExperience.includes(exp.value)}
                                                                    onChange={handleExperienceCheckboxChange}
                                                                    className='me-2'
                                                                />
                                                                {exp.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Search by Availability Section */}
                                <div className='form-group col-md-12'>
                                    <div className="accordion" id="availabilityAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingAvailability">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAvailability" aria-expanded="true" aria-controls="collapseAvailability">
                                                    Search by Availability
                                                </button>
                                            </h2>
                                            <div id="collapseAvailability" className="accordion-collapse collapse show" aria-labelledby="headingAvailability" data-bs-parent="#availabilityAccordion">
                                                <div className="accordion-body">
                                                    {/* Default Availability Checkboxes */}
                                                    {availabilityOptions.map((option, index) => (
                                                        <div key={index}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    value={option.value}
                                                                    checked={selectedAvailability.includes(option.value)}
                                                                    onChange={handleAvailabilityCheckboxChange}
                                                                    className='me-2'
                                                                />
                                                                {option.label}
                                                            </label>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group col-md-12'>

                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value={infraChecked}
                                            onChange={() => setInfraChecked(!infraChecked)} />
                                        <label class="form-check-label" for="flexSwitchCheckChecked">Infracon Registered</label>
                                    </div>
                                </div>

                                <div className='col-md-12 d-flex gap-1 mt-4 mt-md-0 mb-3'>
                                    <button className="btn btn-warning w-50 rounded-3" type="button" onClick={fetchFilteredData}>
                                        Apply
                                    </button>
                                    <button
                                        className="btn btn-secondary w-50 rounded-3"
                                        onClick={() => {
                                            fetchFilteredData();
                                            setSearchLocation('');
                                            setSelectedSector([]);
                                            setSelectedAvailability('');
                                            setSearchTerm('');
                                            setSelectedExperience('');
                                            setInfraChecked(false)
                                        }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-9'>
                        <div className='banner text-center'>
                            <div className="d-flex w-100" style={{ padding: "20px" }}>
                                <div className="d-flex align-items-center shadow-sm border border-2" style={{ padding: "5px 10px" }}>
                                    {/* Job Title Input */}
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <i className="bi bi-search text-muted me-2"></i>
                                        <input
                                            type="text"
                                            className="form-control border-0 shadow-none"
                                            placeholder={'Search by Name/Designation'}
                                            style={{ outline: "none" }}
                                            value={searchTerm}
                                            onChange={handleSearchTermChange}
                                        />
                                    </div>

                                    <div className="vr mx-2"></div>

                                    <div className="d-flex align-items-center flex-grow-1">
                                        <i className="bi bi-geo-alt text-muted me-2"></i>
                                        <input
                                            type="text"
                                            className="form-control border-0 shadow-none"
                                            placeholder="Location"
                                            style={{ outline: "none" }}
                                            value={searchLocation}
                                            onChange={handleSearchLocationChange}
                                        />
                                    </div>

                                    <button
                                        className="btn btn-warning rounded-circle d-flex align-items-center justify-content-center custom-search-btn"
                                        style={{ width: "40px", height: "40px" }}
                                        onClick={fetchFilteredData}
                                    >
                                        <i className="bi bi-arrow-right text-white"></i>
                                    </button>

                                </div>
                            </div>
                        </div>

                        {data && data.length > 0 ? (
                            <div className='row mt-4'>
                                {data.map((user, index) => (
                                    <div className="col-md-12 mb-3" key={index}>
                                        <div className="job-listing-card d-flex flex-column flex-md-row">
                                            {/* Company Logo */}
                                            {user?.details?.profile_picture ? (
                                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.details?.profile_picture}`}
                                                    alt="Company Logo"
                                                    className="job-logo img-fluid mt-1"
                                                />
                                            ) : (
                                                <div className="letter-cap me-3">{user?.name?.charAt(0)}</div>
                                            )}

                                            {/* Job Details */}
                                            <div className="job-details flex-grow-1">

                                                <Link to={`/profile/${user?.slug}`} className="text-decoration-none text-dark">
                                                    <h5 className="job-title text-primary">{user?.name}</h5>
                                                </Link>
                                                <p className="job-company text-black text-muted">{user?.details?.designation}</p>
                                                <p className="job-location text-muted">{user?.details?.current_location}</p>
                                                {/* <p className="job-location text-muted">{user?.details?.citizenship}</p> */}

                                                {/* Job Badges */}
                                                <div className="job-meta flex-wrap">
                                                    <span className="badge job-badge rounded-pill">{user?.details?.years_of_experience ?? 0} years</span>
                                                    
                                                    <span className="badge job-badge rounded-pill">{user?.details?.availability}</span>
                                                    {user?.details?.sectors?.length > 0 && (
                                                        <>
                                                            {user?.details?.sectors.map((sector, idx) => (
                                                                <span key={idx} className="badge job-badge rounded-pill">{sector}</span>
                                                            ))}
                                                            
                                                        </>
                                                    )}


                                                </div>
                                                <div className='meta-info'>
                                                    {user?.details?.experience && (
                                                        <>
                                                            <div className='work-exprience'>

                                                                <label>Work Experience</label>
                                                                <ul className='list-unstyled'>
                                                                    {isReadMore
                                                                        ? user?.details?.experience.map((exp, index) => (
                                                                            <li key={index}>
                                                                                <span className="material-icons">chevron_right</span>{exp.title} - {exp?.company_name}
                                                                            </li>
                                                                        ))
                                                                        : user?.details?.experience.slice(0, 3).map((exp, index) => (
                                                                            <li key={index}>
                                                                                <span className="material-icons">chevron_right</span>{exp.title} - {exp?.company_name}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                                {/* "Read more" / "Read less" button */}
                                                                {user?.details?.experience.length > 3 && (
                                                                    <p className="text-secondary" onClick={toggleReadMore} style={{ cursor: 'pointer' }}>
                                                                        {isReadMore ? 'Read less' : 'Read more'}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </>


                                                    )}
                                                </div>

                                            </div>

                                            {/* Save Job Button */}
                                            {user?.details?.resume_attachment && (
                                                // <div className="save-job btn-sm btn-warning">
                                                //     <i className="bi bi-bookmark"></i>
                                                // </div>
                                                <>
                                                    {token && userData?.user?.role_id === 1 ? (
                                                        <Link to={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${user?.details?.resume_attachment}`} target='_blank'>
                                                            <p className='applicants_profile text-end text-success'>
                                                                <span className="material-symbols-outlined">draft</span> CV available
                                                            </p>
                                                        </Link>
                                                    ) : userData?.user?.role_id === 3 ? (
                                                        <p className='applicants_profile text-end text-success' title="You can download CVs of applicants who applied to their job posts in the Applicant List.">
                                                            <span className="material-symbols-outlined">draft</span> CV available
                                                        </p>
                                                    ) : (
                                                        <p className='applicants_profile text-end text-success'>
                                                            <span className="material-symbols-outlined">draft</span> CV available
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        <hr></hr>
                                    </div>
                                ))}
                            </div>

                        ) : (
                            <h2 className='text-center mt-3'>No Records Found !!</h2>
                        )}
                        <div className='container pagination'>
                            {/* Pagination */}
                            {totalPages > 1 && (
                                <MDPagination size="medium" className="page-list">
                                    {currentPage > 1 && (
                                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                                            <Icon>keyboard_arrow_left</Icon>
                                        </MDPagination>
                                    )}
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <MDPagination item
                                            key={i + 1}
                                            active={currentPage === i + 1}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </MDPagination>
                                    ))}
                                    {currentPage < totalPages && (
                                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                                            <Icon>keyboard_arrow_right</Icon>
                                        </MDPagination>
                                    )}
                                </MDPagination>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </BasicLayout >

    )
}

export default Index