/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { logout } from '../../../store/slices/authSlice';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { MenuItem } from "@mui/material";
function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const { token, user } = useSelector(selectAuth);
  const authdispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate()

  const redirectTo = user?.user?.role_id === 4 ? '/profile' : '/my-profile';
  const handleLogout = () => {
    // Implement your logout logic here
    authdispatch(logout());
    navigate('/')
    cookies.remove('token', { path: '/' });
  };
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        {token ? (
          <>
            {user?.user?.role_id !== 4 && user?.user?.role_id !== 3 && (
               <Link to='/dashboard'>
               <MenuItem >dashboard</MenuItem>
             </Link>
            )}
            <Link to='/all-projects'>
              <MenuItem >Projects</MenuItem>
            </Link>
            <Link to='/all-jobs'>
              <MenuItem >Jobs</MenuItem>
            </Link>

            <Link to={redirectTo}>
              <MenuItem >Profile</MenuItem>
            </Link>
            {(user?.user?.role_id == 4 || user?.user?.role_id == 3) && (
              <Link to={'/my-dashboard'}>
                <MenuItem >Dashboard</MenuItem>
              </Link>
            )}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </>
        )
          :
          <>
            <DefaultNavbarLink name="projects" route="/all-projects" />
            <DefaultNavbarLink name="jobs" route="/all-jobs" />

            <DefaultNavbarLink
              name="sign in"
              route="/sign-in"

            />
          </>

        }

      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
